import ReportService from '../../../services/ReportService';

export const exportReportToPdf = async (id, type, setIsExporting) => {
  try {
    setIsExporting(true);

    const pdf = await ReportService.getReportPdf({ id, type });

    const url = window.URL.createObjectURL(new Blob([pdf]));
    const link = document.createElement('a');
    link.href = `data:application/pdf;base64,${pdf}`;
    link.setAttribute('download', 'report.pdf'); // Specify the file name
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error(error);
  } finally {
    setIsExporting(false);
  }
};

export const downloadReportXlsx = async (meetingId, setIsExporting) => {
  try {
    setIsExporting(true);

    const response = await ReportService.getReportXlsx(meetingId);

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const a = document.createElement('a');
    a.href = url;

    let filename = 'report.xlsx'; // fallback filename
    const disposition = response.headers['content-disposition'];

    if (disposition && disposition.indexOf('attachment') !== -1) {
      const filenameMatch = disposition.match(/filename="(.+)"/);
      if (filenameMatch && filenameMatch[1]) {
        // eslint-disable-next-line prefer-destructuring
        filename = filenameMatch[1];
      }
    }

    a.download = filename;
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.log(error);
  } finally {
    setIsExporting(false);
  }
};
