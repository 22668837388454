/* eslint-disable new-cap */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';

import { useParams, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';

import classNames from 'classnames';
import FolderReportHeader from '../../components/Reports/FolderReportHeader';
// import ReportHeader from '../../components/Reports/ReportHeader';
import ReportTabs from '../../components/Reports/ReportTabs';
import FolderStats from '../../components/Reports/FolderStats';
import UsersTable from '../../components/Reports/UsersTable';
import TabContentTitle from '../../components/Reports/TabContentTitle';
import VideosReport from '../../components/Reports/VideosReport';
import SummaryViewerReportModal from '../../modals/SummaryViewerReportModal';
import Layout from '../../containers/Layout';
import Breadcrumbs from '../../components/Breadcrumbs';
import FilledButton from '../../components/Buttons/FilledButton';

import classes from './styles.module.scss';
import ReportService from '../../services/ReportService';
import { exportReportToPdf } from './helpers';
import downloadIcon from '../../assets/images/reports/download.svg';

const tabs = ['Overview', 'Videos', 'Viewers'];

export default function ViewerReportPage({ withLayout }) {
  const [currentTab, setCurrentTab] = useState(tabs[0]);
  const [sortField, setSortField] = useState('name');
  const [sortOrder, setSortOrder] = useState('ASC');
  const [isSummaryModalVisible, setIsSummaryModalVisible] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [currentContentType, setCurrentContentType] = useState(null);
  const [currentContentTitle, setCurrentContentTitle] = useState(null);
  const [isExporting, setIsExporting] = useState(false);

  const { id } = useParams();

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const isExport = query.get('export');

  const { data: folderStats } = useQuery({
    queryKey: ['folderStats', id],
    queryFn: () => ReportService.getFolderStats(id),
  });

  const { data: folderSummaryReport } = useQuery({
    queryKey: ['viewerUserSummaryReport', id],
    queryFn: () => ReportService.getViewerUserSummaryFolderReport(id),
  });

  const { data: videosReport } = useQuery({
    queryKey: ['videosReport', id, sortField, sortOrder],
    queryFn: () => ReportService.getVideosReport({ id, sortField, sortOrder }),
    keepPreviousData: true,
  });

  const showSummaryReportModal = (contentId, contentType, contentTitle) => {
    setCurrentId(contentId);
    setCurrentContentType(contentType);
    setCurrentContentTitle(contentTitle);
    setIsSummaryModalVisible(true);
  };

  const content = (
    <div className={classNames(classes.ViewerReportPage)}>
      {/*    <ReportHeader
        reportTitle="Viewer Report"
        contentType="folder"
        contentTitle={folderStats?.name}
        folderOwner={folderStats?.folderOwnerName}
        exportReportToPdf={() => exportReportToPdf(id, setIsExporting)}
        exportTableToExcel={() => exportTableToExcel(currentTab)}
        isExport={isExport}
        isExporting={isExporting}
      /> */}
      <FolderReportHeader title={folderStats?.name} isExport={isExport} />
      {!isExport && (
        <ReportTabs
          tabs={tabs}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      )}
      {(currentTab === 'Overview' || isExport) && (
        <FolderStats folderStats={folderStats} isExport={isExport} />
      )}
      {(currentTab === 'Viewers' || isExport) && (
        <div className={classes.tableContainer}>
          <div
            style={{
              marginTop: isExport ? 51 : '',
            }}
          >
            <TabContentTitle title="User Report Summary" isExport={isExport} />
          </div>
          <UsersTable report={folderSummaryReport} isExport={isExport} />
        </div>
      )}
      {/* Visibility is used here instead of conditional rendering to keep the state of the component and prevent root folder from jumping on first render */}
      <div
        style={{
          visibility:
            currentTab === 'Videos' || isExport ? 'visible' : 'hidden',
          opacity: currentTab === 'Videos' || isExport ? 1 : 0,
          display: currentTab === 'Videos' || isExport ? '' : 'none',
          marginTop: isExport ? 51 : '',
        }}
      >
        <VideosReport
          report={videosReport}
          sortField={sortField}
          sortOrder={sortOrder}
          setSortField={setSortField}
          setSortOrder={setSortOrder}
          showSummaryReportModal={showSummaryReportModal}
          isExport={isExport}
        />
      </div>
      {!isExport && (
        <SummaryViewerReportModal
          show={isSummaryModalVisible}
          handleClose={() => setIsSummaryModalVisible(false)}
          id={currentId}
          contentType={currentContentType}
          contentTitle={currentContentTitle}
        />
      )}
    </div>
  );

  return withLayout ? (
    <Layout>
      <div className={classes.header}>
        <Breadcrumbs
          breadcrumbsOptions={[
            { label: 'My Library', to: '/my-library' },
            {
              label: folderStats?.name,
            },
          ]}
        />{' '}
        <FilledButton
          onClick={() => exportReportToPdf(id, setIsExporting)}
          isDisabled={isExporting}
          width={196}
        >
          Export{' '}
          <img src={downloadIcon} alt="Export" className={classes.exportIcon} />
        </FilledButton>
      </div>
      {content}
    </Layout>
  ) : (
    content
  );
}
