import React from 'react';

import Stat from './Stat';
import TabContentTitle from '../TabContentTitle';

import classes from './styles.module.scss';
import { createFolderStats } from './helpers';

export default function FolderStats({ folderStats, isExport }) {
  return (
    <div className={classes.FolderStats}>
      <TabContentTitle title="Viewer Report Summary" isExport={isExport} />
      <div className={classes.stats}>
        {createFolderStats(folderStats).map((stat) => (
          <Stat key={stat.title} {...stat} />
        ))}
      </div>
    </div>
  );
}
