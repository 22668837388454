/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/media-has-caption */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from 'react';

import { useQuery } from 'react-query';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import MeetingVideoPlayer from '../../components/Meeting/MeetingVideoPlayer';
import Navigation from '../../components/Navigation';
import PreviewSections from '../../components/PreviewSections';
import Comments from '../../components/Meeting/Comments';
import SignIn from '../../components/Meeting/SignIn';
import Breadcrumbs from '../../components/Breadcrumbs';
import Layout from '../../containers/Layout';
import ConfirmReviewModal from '../../modals/ConfirmReviewModal';
import VideoApproval from '../../components/Meeting/VideoApproval';
import PreviewSectionsMobile from '../../components/PreviewSectionsMobile';
import FilledButton from '../../components/Buttons/FilledButton';

import { hasAuthData } from '../../helpers/authStorage';
import MeetingService from '../../services/MeetingService';
import UserService from '../../services/UserService';
import { UiContext } from '../../context/UiContext';
import classes from './styles.module.scss';
import { useUiStore, useMeetingViewStore } from '../../store';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import useCurrentSectionIndex from './hooks/useCurrentSectionIndex';
import CommentService from '../../services/CommentService';

let waveFormTimer;
let animationId;

export default function Meeting({
  meeting,
  accessPassword,
  getSharedMeeting,
  preview,
  email,
}) {
  const [videoProgress, setVideoProgress] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [isSignUpPopupVisible, setIsSignUpPopupVisible] = useState(false);
  const [audioSectionsWithoutBackground, setAudioSectionsWithoutBackground] =
    useState([]);
  const [
    currentAudioSectionWithoutBackground,
    setCurrentAudioSectionWithoutBackground,
  ] = useState(null);
  const [currentAudioSectionProgress, setCurrentAudioSectionProgress] =
    useState(0);
  const [isSectionsVisible, setIsSectionsVisible] = useState(true);
  const [isDraggingProgressBar, setIsDraggingProgressBar] = useState(false);
  const [isConfirmReviewModalVisible, setIsConfirmReviewModalVisible] =
    useState(false);
  const [reviewDecision, setReviewDecision] = useState('');
  const [shouldReturnToFulscreen, setShouldReturnToFulscreen] = useState(false);
  const [sections, setSections] = useState([]);
  const [signedS3Url, setSignedS3Url] = useState('');

  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const meetingVersion = queryParams.get('version');
  const reviewId = queryParams.get('reviewId');

  const videoRef = useRef();
  const waveFormContainerRef = useRef();
  const progressBarRef = useRef();
  const previewSectionsRef = useRef();
  const fullscreenContainerRef = useRef();

  const breadcrumbsOptions = useUiStore((state) => state.breadcrumbsOptions);
  const setMinSectionIndex = useMeetingViewStore(
    (state) => state.setMinSectionIndex
  );
  const timelineMapping = useMeetingViewStore((state) => state.timelineMapping);

  const isMobile = useCheckMobileScreen();

  const isAuthenticated = hasAuthData();
  const { setRedirectAfterLogin, setIsSignInHintVisible } =
    useContext(UiContext);

  const { data, refetch, isError } = useQuery(
    ['meeting', id],
    () => MeetingService.getMeeting(id, meetingVersion),
    {
      enabled: !meeting,
      keepPreviousData: true,
      retry: false,
    }
  );

  const { data: comments, refetch: refetchComments } = useQuery(
    ['comments', id],
    () => CommentService.getMeetingComments(id),
    {
      enabled: !meeting,
      keepPreviousData: true,
      retry: false,
    }
  );

  const {
    currentSectionIndex,
    setCurrentSectionIndex,
    getNextSectionIndexCandidate,
  } = useCurrentSectionIndex({
    videoRef,
    sections,
  });

  const refetchMeeting = useCallback(() => {
    if (meeting) {
      getSharedMeeting({ password: accessPassword });

      return;
    }

    refetch();
  }, [accessPassword, getSharedMeeting, meeting, refetch]);

  if (!isAuthenticated && isError) {
    const query = reviewId ? `reviewId=${reviewId}` : '';

    setRedirectAfterLogin(`/my-videos/${id}?${query}`);
    navigate('/sign-in');
  }

  useEffect(() => {
    if (!data?.MeetingReviews.length) {
      return;
    }

    const revId = data.MeetingReviews[0].id;

    queryParams.set('reviewId', revId);

    const updatedSearchString = queryParams.toString();
    const updatedUrl = `${window.location.pathname}?${updatedSearchString}`;

    navigate(updatedUrl);
  }, [data?.MeetingReviews]);

  useEffect(() => {
    if (!isAuthenticated) {
      setIsSignInHintVisible(true);
    } else {
      setIsSignInHintVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (data) {
      setQuestions(
        data.Sections?.filter((section) => section.type === 'question')?.map(
          (section) => section.Question
        )
      );
    }
  }, [data]);

  useEffect(() => {
    if (meeting) {
      setQuestions(
        meeting.Sections?.filter((section) => section.type === 'question')?.map(
          (section) => section.Question
        )
      );
    }
  }, [meeting]);

  useEffect(() => {
    if (sections.length) {
      return;
    }

    setSignedS3Url(data?.signedS3Url);

    if (data?.Sections) {
      setSections(data?.Sections);
    } else if (meeting?.Sections) {
      setSections(meeting?.Sections);
    }
  }, [data, meeting, sections]);

  const confirmMeetingView = useCallback(() => {
    if (email) {
      MeetingService.confirmMeetingViewAnonymously({ meetingId: id, email });
    } else if (isAuthenticated) {
      MeetingService.confirmMeetingView(id);
    }
  }, [email, id, isAuthenticated]);

  const { data: user, refetch: refetchUser } = useQuery(
    'me',
    UserService.getMe,
    {
      enabled: !meeting,
    }
  );

  useEffect(() => {
    if (user && meeting) {
      getSharedMeeting({ password: accessPassword, userId: user.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const animateProgressBar = useCallback(() => {
    setVideoProgress(
      (100 / videoRef.current?.duration) * videoRef.current?.currentTime
    );

    animationId = requestAnimationFrame(animateProgressBar);
  }, []);

  const stopAnimation = useCallback(() => {
    cancelAnimationFrame(animationId);
  }, []);

  useEffect(() => {
    return () => {
      stopAnimation();
    };
  }, [animateProgressBar, stopAnimation, videoRef]);

  useEffect(() => {
    const videoRefCurrent = videoRef?.current;

    const handleLoadMetadata = () => {
      setVideoDuration(videoRef.current.duration);
    };
    if (videoRefCurrent) {
      videoRefCurrent.addEventListener('loadedmetadata', handleLoadMetadata);
    }

    return () => {
      if (videoRefCurrent) {
        videoRefCurrent.removeEventListener(
          'loadedmetadata',
          handleLoadMetadata
        );
      }
    };
  }, []);

  useEffect(() => {
    const calculateCurrentAudioSectionProgress = (
      currentTime,
      currentAudioSection
    ) => {
      const sectionCurrentTime = currentTime - currentAudioSection.startTime;
      const sectionProgress =
        (1 / currentAudioSection.section.duration) * sectionCurrentTime;

      return Math.min(Math.abs(sectionProgress), 1);
    };

    const showWaveForm = () => {
      if (!audioSectionsWithoutBackground.length) {
        return;
      }

      const currentTime = videoRef.current?.currentTime;

      const currentAudioSection = audioSectionsWithoutBackground.find(
        (sect) => {
          return (
            sect.startTime - 0.3 <= currentTime && sect.endTime >= currentTime
          );
        }
      );

      if (currentAudioSection) {
        setCurrentAudioSectionWithoutBackground(currentAudioSection);
        const sectionProgress = calculateCurrentAudioSectionProgress(
          currentTime,
          currentAudioSection
        );
        setCurrentAudioSectionProgress(sectionProgress);
      } else {
        setCurrentAudioSectionWithoutBackground(null);
      }
    };

    waveFormTimer = setInterval(() => {
      showWaveForm();
    }, 50);

    return () => {
      clearInterval(waveFormTimer);
    };
  }, [audioSectionsWithoutBackground, videoDuration]);

  // Get audiosections without background image and calculate time intervals
  useEffect(() => {
    if (!data) {
      return;
    }

    // eslint-disable-next-line no-shadow
    const getAudioSectionStartTime = (audioSection, sections) => {
      if (audioSection.position === 0) {
        return 0;
      }

      const prevSections = sections.slice(0, audioSection.position);
      const startTime = prevSections.reduce((acc, curVal) => {
        return acc + curVal.duration;
      }, 0);

      return startTime;
    };

    const getAudioSectionEndTime = (audioSection, sectionStartTime) => {
      const endTime = sectionStartTime + audioSection.section.duration;

      return endTime;
    };

    // eslint-disable-next-line no-shadow
    const getAudioSectionsTimeIntervals = (sections) => {
      const audioSections = [];
      sections.forEach((section, index) => {
        if (section.type === 'audio' && !section.audioImagePath) {
          audioSections.push({
            section,
            position: index,
          });
        }
      });

      const sectionsWithTimeIntervals = [];

      audioSections.forEach((section) => {
        const startTime = getAudioSectionStartTime(section, sections);

        const isLastSection =
          sections[sections.length - 1].id === section.section.id;

        const endTime = isLastSection
          ? getAudioSectionEndTime(section, startTime) + 10
          : getAudioSectionEndTime(section, startTime);
        sectionsWithTimeIntervals.push({
          section: section.section,
          startTime,
          endTime,
        });
      });

      return sectionsWithTimeIntervals;
    };

    if (data) {
      setAudioSectionsWithoutBackground(
        getAudioSectionsTimeIntervals(data?.Sections)
      );
    }
  }, [data]);

  const progressBarHandler = useCallback(
    (event) => {
      const progressBar = progressBarRef.current;
      const clickPosition =
        event.clientX - progressBar.getBoundingClientRect().left;
      const progressBarWidth = progressBar.offsetWidth;
      const percentage = clickPosition / progressBarWidth;
      const currentTime = percentage * videoDuration;

      videoRef.current.currentTime = currentTime;

      animateProgressBar();
    },
    [videoDuration, animateProgressBar]
  );

  useEffect(() => {
    if (!isSignUpPopupVisible) {
      refetchUser();
    }
  }, [isSignUpPopupVisible, refetchUser]);

  useEffect(() => {
    let isPausedWhileDragging = false;

    const handleMouseMove = (event) => {
      if (!isDraggingProgressBar) {
        return;
      }

      if (!videoRef.current.paused) {
        videoRef.current.pause();
        isPausedWhileDragging = true;
      }

      progressBarHandler(event);
    };

    const handleMouseUp = () => {
      if (isDraggingProgressBar && isPausedWhileDragging) {
        setIsPlaying(true);
        videoRef.current.play();
      }

      setIsDraggingProgressBar(false);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDraggingProgressBar, progressBarHandler]);

  const resetMinSectionIndex = useCallback(
    (currentTime) => {
      const sectionIndex = getNextSectionIndexCandidate(
        timelineMapping,
        currentTime
      );

      setMinSectionIndex(sectionIndex);
    },
    [getNextSectionIndexCandidate, setMinSectionIndex, timelineMapping]
  );

  const rewindVideoByComment = (meetingTime) => {
    resetMinSectionIndex(meetingTime);
    videoRef.current.currentTime = meetingTime;

    if (videoRef.current.duration !== meetingTime) {
      setIsPlaying(true);
      videoRef.current.play();
    }

    animateProgressBar();
  };

  const openConfirmReviewModal = (decision) => {
    setIsConfirmReviewModalVisible(true);
    setReviewDecision(decision);
  };

  const commentsSource = meeting || { Comments: comments };

  const isMobileUpload = data?.type === 'mobile uploads';

  // Reset min section index on meeting page unmount
  useEffect(() => {
    return () => {
      setMinSectionIndex(0);
    };
  }, [setMinSectionIndex]);

  if (isSignUpPopupVisible) {
    return <SignIn handleClose={() => setIsSignUpPopupVisible(false)} />;
  }

  return (
    <Layout hasUserBlock={isAuthenticated} style={{ overflowX: 'hidden' }}>
      <div className={classes.Meeting}>
        <div className={classes.breadcrumbsContainer}>
          <Breadcrumbs breadcrumbsOptions={breadcrumbsOptions} />
        </div>
        <div className={classes.headerContainer}>
          {!isMobile && <Navigation />}
          {reviewId && (
            <VideoApproval openConfirmReviewModal={openConfirmReviewModal} />
          )}
        </div>
        {preview && (
          <div className={classes.editButtonContainer}>
            <FilledButton
              onClick={() => {
                navigate(`/my-library/${id}/edit`);
              }}
            >
              Edit
            </FilledButton>
          </div>
        )}
        <MeetingVideoPlayer
          currentAudioSectionWithoutBackground={
            currentAudioSectionWithoutBackground
          }
          currentAudioSectionProgress={currentAudioSectionProgress}
          meeting={meeting}
          waveFormContainerRef={waveFormContainerRef}
          videoRef={videoRef}
          isDraggingProgressBar={isDraggingProgressBar}
          progressBarRef={progressBarRef}
          progressBarHandler={progressBarHandler}
          setIsDraggingProgressBar={setIsDraggingProgressBar}
          videoProgress={videoProgress}
          videoDuration={videoDuration}
          folderId={data?.folderId}
          title={data?.title}
          signedS3Url={signedS3Url}
          animateProgressBar={animateProgressBar}
          isSectionsVisible={isSectionsVisible}
          setIsSectionsVisible={setIsSectionsVisible}
          stopAnimation={stopAnimation}
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
          meetingVersion={meetingVersion}
          confirmMeetingView={confirmMeetingView}
          fullscreenContainerRef={fullscreenContainerRef}
          isMobileUpload={isMobileUpload}
          onRewind={resetMinSectionIndex}
        />
        {!meetingVersion && isSectionsVisible && !isMobileUpload && (
          <>
            {isMobile ? (
              <PreviewSectionsMobile
                videoRef={videoRef}
                animateProgressBar={animateProgressBar}
                previewSectionsRef={previewSectionsRef}
                setIsPlaying={setIsPlaying}
                currentSectionIndex={currentSectionIndex}
                shouldReturnToFulscreen={shouldReturnToFulscreen}
                setShouldReturnToFulscreen={setShouldReturnToFulscreen}
                fullscreenContainerRef={fullscreenContainerRef}
                setIsSignUpPopupVisible={setIsSignUpPopupVisible}
                setCurrentSectionIndex={setCurrentSectionIndex}
                preview={preview}
                refetchMeeting={refetchMeeting}
                sections={sections}
              />
            ) : (
              <PreviewSections
                videoRef={videoRef}
                animateProgressBar={animateProgressBar}
                previewSectionsRef={previewSectionsRef}
                setIsPlaying={setIsPlaying}
                isPlaying={isPlaying}
                currentSectionIndex={currentSectionIndex}
                shouldReturnToFulscreen={shouldReturnToFulscreen}
                setShouldReturnToFulscreen={setShouldReturnToFulscreen}
                fullscreenContainerRef={fullscreenContainerRef}
                setIsSignUpPopupVisible={setIsSignUpPopupVisible}
                setCurrentSectionIndex={setCurrentSectionIndex}
                preview={preview}
                refetchMeeting={refetchMeeting}
                sections={sections}
              />
            )}
          </>
        )}
        {!preview && !meetingVersion && (
          <Comments
            shouldNotify={data?.shouldNotify}
            commentsSource={commentsSource}
            questions={questions}
            user={user}
            refetchMeeting={refetchMeeting}
            videoRef={videoRef}
            id={id}
            setIsSignUpPopupVisible={setIsSignUpPopupVisible}
            rewindVideoByComment={rewindVideoByComment}
            isAuthenticated={isAuthenticated}
            refetchComments={refetchComments}
          />
        )}
        {/* <QuestionModal
          show={isQuestionModalVisible}
          handleClose={hideQuestionModal}
          question={questionToShow}
          refetchMeeting={refetchMeeting}
          preview={preview}
        /> */}
        <ConfirmReviewModal
          show={isConfirmReviewModalVisible}
          handleClose={() => setIsConfirmReviewModalVisible(false)}
          meetingId={id}
          reviewId={reviewId}
          reviewDecision={reviewDecision}
          email={user?.email || email}
        />
      </div>
    </Layout>
  );
}
