import React, { useState, useEffect } from 'react';

import { createPortal } from 'react-dom';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function FavoritedPopup({
  sectionRef,
  isVisible,
  setIsVisible,
}) {
  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0);

  useEffect(() => {
    if (sectionRef.current) {
      const rect = sectionRef.current.getBoundingClientRect();

      const zoom = getComputedStyle(document.documentElement)
        .getPropertyValue('--zoom')
        .trim();

      // eslint-disable-next-line no-shadow
      const { left, top, width } = rect;

      const centerX = left / zoom + width / 2 / zoom;

      setLeft(centerX);
      setTop(top / zoom);
    }
  }, [sectionRef, isVisible]);

  useEffect(() => {
    let timer;
    if (isVisible) {
      timer = setTimeout(() => {
        setIsVisible(false);
      }, 3000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isVisible, setIsVisible]);

  return createPortal(
    <div
      className={classNames(classes.FavoritedPopup, {
        [classes.visible]: isVisible,
      })}
      style={{
        left,
        top,
      }}
    >
      Section saved successfully. It will appear in your &quot;Saved
      Section&quot; library soon!
    </div>,
    document.body
  );
}
