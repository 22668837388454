/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef } from 'react';

import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import ImportFileModal from '../../../modals/ImportFileModal';
import UploadFilesModal from '../../../modals/UploadFilesModal';
import Menu from './Menu';

import useOnClickOutside from '../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';
import adjustTooltipOnZoom from '../../../helpers/adjustTooltipOnZoom';

export default function SectionMenu({
  onCamera,
  onScreen,
  isScreenEnabled,
  isCameraEnabled,
  isRecording,
  showPlaceholder,
  onQuestion,
  setIsQuestionFormVisible,
  isQuestionFormVisible,
  onAssignSection,
  isAssignSectionFormVisible,
  meeting,
  meetingTitle,
  setIsNoTitleWarningVisible,
  isAudioEnabled,
  onAudio,
  sections,
  createMeeting,
  folderId,
  setMeetingId,
  refetchSections,
  setFile,
  setSectionTitle,
  createDefaultSectionName,
  createSection,
  updateTextSection,
  prevSectionId,
  hidePlaceholder,
}) {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isImportFileModalVisible, setIsImportFileModalVisible] =
    useState(false);
  const [isUploadFilesModalVisible, setIsUploadFilesModalVisible] =
    useState(false);

  const menuRef = useRef();
  const toggleRef = useRef();
  const fileInputRef = useRef();
  const plusSignRef = useRef();

  const navigate = useNavigate();

  useOnClickOutside(menuRef, () => setIsMenuVisible(false), toggleRef);

  const handleMyComputerClick = () => {
    fileInputRef.current.click();

    const sectionTitle = createDefaultSectionName({
      placeholderType: 'video',
    });

    setSectionTitle(sectionTitle);
  };

  return (
    <>
      <div
        ref={toggleRef}
        className={classNames(
          classes.SectionMenu,
          !sections?.length && classes.emptyList
        )}
        onClick={() => setIsMenuVisible((prevState) => !prevState)}
      >
        <div className={classes.toggle}>
          <OverlayTrigger
            onToggle={adjustTooltipOnZoom}
            placement="top-start"
            overlay={
              <Tooltip>
                Choose different ways to record content for your video
              </Tooltip>
            }
          >
            <svg
              ref={plusSignRef}
              width="34"
              height="33"
              viewBox="0 0 34 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.8361 0V12.1291H0.707031V20.2151H12.8361V32.3442H20.9222V20.2151H33.0513V12.1291H20.9222V0H12.8361Z"
                fill="#F8585C"
              />
            </svg>
          </OverlayTrigger>
          <span>Add Section</span>
        </div>

        <Menu
          onCamera={onCamera}
          onScreen={onScreen}
          isScreenEnabled={isScreenEnabled}
          isCameraEnabled={isCameraEnabled}
          isRecording={isRecording}
          showPlaceholder={showPlaceholder}
          onQuestion={onQuestion}
          setIsQuestionFormVisible={setIsQuestionFormVisible}
          isQuestionFormVisible={isQuestionFormVisible}
          onAssignSection={onAssignSection}
          isAssignSectionFormVisible={isAssignSectionFormVisible}
          meeting={meeting}
          meetingTitle={meetingTitle}
          setIsNoTitleWarningVisible={setIsNoTitleWarningVisible}
          isAudioEnabled={isAudioEnabled}
          onAudio={onAudio}
          sections={sections}
          createMeeting={createMeeting}
          folderId={folderId}
          setMeetingId={setMeetingId}
          refetchSections={refetchSections}
          setSectionTitle={setSectionTitle}
          createDefaultSectionName={createDefaultSectionName}
          createSection={createSection}
          updateTextSection={updateTextSection}
          prevSectionId={prevSectionId}
          hidePlaceholder={hidePlaceholder}
          fileInputRef={fileInputRef}
          plusSignRef={plusSignRef}
          setIsMenuVisible={setIsMenuVisible}
          isMenuVisible={isMenuVisible}
          menuRef={menuRef}
          setIsUploadFilesModalVisible={setIsUploadFilesModalVisible}
          setIsImportFileModalVisible={setIsImportFileModalVisible}
        />
        <input
          type="file"
          style={{ display: 'none' }}
          onChange={(event) => {
            showPlaceholder('', 'video', prevSectionId);
            setFile(event.target.files[0]);
          }}
          onClick={(event) => event.stopPropagation()}
          ref={fileInputRef}
          accept="video/mp4, video/quicktime, video/x-ms-wmv, video/x-msvideo, video/webm"
        />
      </div>
      <ImportFileModal
        show={isImportFileModalVisible}
        handleClose={() => {
          setIsImportFileModalVisible(false);
        }}
        onMobileUploadsClick={() => {
          navigate(
            `/my-videos?tab=mobile uploads&meetingId=${meeting.id}${
              prevSectionId ? `&prevSectionId=${prevSectionId}` : ''
            }`
          );
        }}
        onSavedSectionsClick={() => {
          navigate(
            `/my-videos?tab=saved&meetingId=${meeting.id}${
              prevSectionId ? `&prevSectionId=${prevSectionId}` : ''
            }`
          );
        }}
        onMyComputerClick={handleMyComputerClick}
      />
      <UploadFilesModal
        withText
        show={isUploadFilesModalVisible}
        handleClose={() => setIsUploadFilesModalVisible(false)}
        title="Upload Image"
        onSubmit={async (files) => {
          await createSection({
            meetingId: meeting.id,
            title: 'Image',
            images: files.files,
            text: files.text,
          });
        }}
      />
    </>
  );
}
