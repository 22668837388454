import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

export default function TabContentTitle({ title, isExport }) {
  return (
    <h3
      className={classNames(classes.TabContentTitle, {
        [classes.export]: isExport,
      })}
    >
      {title}
    </h3>
  );
}
