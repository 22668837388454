/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef } from 'react';

import Section from './Section';

import classes from './styles.module.scss';

export default function PreviewSectionsMobile({
  videoRef,
  setIsPlaying,
  currentSectionIndex,
  shouldReturnToFulscreen,
  setShouldReturnToFulscreen,
  fullscreenContainerRef,
  setIsSignUpPopupVisible,
  setCurrentSectionIndex,
  preview,
  refetchMeeting,
  sections,
}) {
  const currentSectionRef = useRef();
  const containerRef = useRef(null);

  // When the current section changes, scroll it into view.
  useEffect(() => {
    if (containerRef.current && currentSectionRef.current) {
      const container = containerRef.current;
      const currentItem = currentSectionRef.current;

      // Get the width of the container
      const containerWidth = container.clientWidth;
      // Calculate the left offset of the current item relative to the container
      const itemOffsetLeft = currentItem.offsetLeft;
      // Get the width of the current item
      const itemWidth = currentItem.offsetWidth;

      // Calculate the new scroll position: center the current item in the container.
      const newScrollLeft = itemOffsetLeft - containerWidth / 2 + itemWidth / 2;

      // Scroll the container horizontally
      container.scrollTo({
        left: newScrollLeft,
        top: container.scrollTop,
        behavior: 'smooth',
      });
    }
  }, [currentSectionIndex]);

  return (
    <ul className={classes.PreviewSectionsMobile} ref={containerRef}>
      {sections.map((section, index) => {
        const isCurrent = currentSectionIndex === index;

        return (
          <Section
            key={section.id}
            section={section}
            index={index}
            isCurrent={isCurrent}
            videoRef={videoRef}
            setIsPlaying={setIsPlaying}
            shouldReturnToFulscreen={shouldReturnToFulscreen}
            setShouldReturnToFulscreen={setShouldReturnToFulscreen}
            fullscreenContainerRef={fullscreenContainerRef}
            setIsSignUpPopupVisible={setIsSignUpPopupVisible}
            setCurrentSectionIndex={setCurrentSectionIndex}
            currentSectionIndex={currentSectionIndex}
            preview={preview}
            refetchMeeting={refetchMeeting}
            currentSectionRef={currentSectionRef}
            sectionCount={
              sections?.filter((s) => s.type !== 'assigned-section').length
            }
          />
        );
      })}
    </ul>
  );
}
