import axios from 'axios';

import { getAuthData } from '../helpers/authStorage';

const ReportService = {
  async getViewerUserSummaryMeetingReport(meetingId) {
    const response = await axios.get('/reports/meeting-viewer-summary', {
      params: { meetingId },
    });
    return response.data;
  },

  async getViewerUserSummaryFolderReport(folderId) {
    const response = await axios.get('/reports/folder-viewer-summary', {
      params: { folderId },
    });
    return response.data;
  },

  async getFolderStats(folderId) {
    const response = await axios.get('/reports/folder-stats', {
      params: { folderId },
    });
    return response.data;
  },

  async getVideosReport({ id, sortField, sortOrder }) {
    const response = await axios.get('/reports/videos', {
      params: { folderId: id, sortField, sortOrder },
    });
    return response.data;
  },

  async getReportPdf({ id, type }) {
    const { refreshToken, userId } = getAuthData();

    const response = await axios.post('/reports/export', {
      id,
      refreshToken,
      userId,
      type,
    });
    return response.data;
  },

  async getReportXlsx(meetingId) {
    const response = await axios.get(
      '/reports/meeting-viewer-summary/download',
      {
        params: { meetingId },
        responseType: 'blob',
      }
    );
    return response;
  },
};

export default ReportService;
