import React from 'react';

import moment from 'moment';
import classNames from 'classnames';

import classes from './styles.module.scss';

export default function FolderReportHeader({ title, isExport }) {
  return (
    <div
      className={classNames(classes.FolderReportHeader, {
        [classes.export]: isExport,
      })}
    >
      <div className={classes.info}>
        <span className={classes.title}>Title:</span> {title}
      </div>
      <div className={classes.info}>
        <span className={classes.title}>Report date:</span>{' '}
        {moment().format('MMMM DD YYYY')}
      </div>
    </div>
  );
}
