/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
import { useState, useEffect, useMemo, useRef } from 'react';

import { useMeetingViewStore } from '../../../store';

// Precompute a timeline mapping once when sections change
const getTimelineMapping = (sections) => {
  if (!sections) {
    return [];
  }

  const timeline = [];
  let startTime = 0;
  sections.forEach((section, index) => {
    // Save both the original index and the start time for this section
    timeline.push({ index, startTime });
    if (section.duration) {
      startTime += section.duration;
    }
  });
  return timeline;
};

// Select the first section with the same start time as the next section
const getNextSectionIndex = (timelineMapping, nextSectionIndexCandidate) => {
  const nextSectionCandidate = timelineMapping[nextSectionIndexCandidate];
  const sectionsWithTheSameStartTime = timelineMapping.filter(
    (section) => section.startTime === nextSectionCandidate.startTime
  );

  if (sectionsWithTheSameStartTime.length === 1) {
    return nextSectionIndexCandidate;
  }

  return sectionsWithTheSameStartTime?.[0]?.index;
};

const getNextSectionIndexCandidate = (timelineMapping, currentTime) => {
  // Find the section with the highest startTime less than or equal to currentTime.
  let low = 0;
  let high = timelineMapping.length - 1;
  let resultIndex = timelineMapping[0]?.index || 0;
  while (low <= high) {
    const mid = Math.floor((low + high) / 2);
    if (timelineMapping[mid].startTime <= currentTime) {
      resultIndex = timelineMapping[mid].index;
      low = mid + 1;
    } else {
      high = mid - 1;
    }
  }
  return resultIndex;
};

export default function useCurrentSectionIndex({ videoRef, sections }) {
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);

  const timelineMapping = useMeetingViewStore((state) => state.timelineMapping);
  const setTimelineMapping = useMeetingViewStore(
    (state) => state.setTimelineMapping
  );

  // Precompute the timeline mapping when the sections change

  useEffect(() => {
    setTimelineMapping(getTimelineMapping(sections));
  }, [sections, setTimelineMapping]);

  useEffect(() => {
    const watchVideoTime = () => {
      const currentTime = videoRef.current?.currentTime || 0;
      const nextSectionIndexCandidate = getNextSectionIndexCandidate(
        timelineMapping,
        currentTime
      );

      const { minSectionIndex } = useMeetingViewStore.getState();
      const newSectionIndex = getNextSectionIndex(
        timelineMapping,
        nextSectionIndexCandidate
      );

      if (minSectionIndex > newSectionIndex) {
        setCurrentSectionIndex(nextSectionIndexCandidate);
      } else {
        setCurrentSectionIndex(newSectionIndex);
      }

      if (!videoRef.current?.ended && !videoRef.current?.paused) {
        requestAnimationFrame(watchVideoTime);
      }
    };

    const videoElement = videoRef.current;

    if (videoElement) {
      videoElement.addEventListener('play', watchVideoTime);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('play', watchVideoTime);
      }
    };
  }, [timelineMapping, videoRef]);

  return {
    currentSectionIndex,
    setCurrentSectionIndex,
    getNextSectionIndexCandidate,
  };
}
