import React from 'react';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import classNames from 'classnames';

import FilledButton from '../Buttons/FilledButton';

import classes from './styles.module.scss';
import trashIcon from '../../assets/images/create-meeting/delete.svg';
import eyeIcon from '../../assets/images/create-meeting/eye.svg';
import adjustTooltipOnZoom from '../../helpers/adjustTooltipOnZoom';
import undoIcon from '../../assets/images/create-meeting/undo.svg';

export default function CreateMeetingButtons({
  isDeleteButtonDisabled,
  previewMeeting,
  isPreviewButtonDisabled,
  isPublishButtonDisabled,
  sections,
  setIsPublishMeetingModalVisible,
  noContentWarningTimer,
  setIsNoContentWarningPopupVisible,
  isSectionButtonDisabled,
  closeAssignedSection,
  showConfirmActionModal,
  toggleMeetingTrashStatusMutation,
  meetingId,
  fetchedMeeting,
  canUndo,
  undo,
}) {
  return (
    <div className={classes.CreateMeetingButtons}>
      {canUndo ? (
        <OverlayTrigger
          placement="top-start"
          onToggle={adjustTooltipOnZoom}
          overlay={<Tooltip>Undo</Tooltip>}
        >
          <button
            type="button"
            className={classNames(classes.roundButton, classes.undo)}
            onClick={undo}
          >
            <img src={undoIcon} alt="Undo" />
          </button>
        </OverlayTrigger>
      ) : null}
      <OverlayTrigger
        placement="top-start"
        onToggle={adjustTooltipOnZoom}
        overlay={<Tooltip>Move to trash</Tooltip>}
      >
        <button
          disabled={isDeleteButtonDisabled}
          type="button"
          className={classes.roundButton}
          onClick={() => {
            if (isDeleteButtonDisabled) {
              return;
            }
            if (meetingId) {
              showConfirmActionModal({
                message: 'Are you sure you want to delete this video?',
                onConfirm: () => toggleMeetingTrashStatusMutation(meetingId),
              });
            }
          }}
        >
          <img src={trashIcon} alt="Delete" />
        </button>
      </OverlayTrigger>

      <OverlayTrigger
        placement="top-start"
        overlay={<Tooltip>Preview</Tooltip>}
        onToggle={adjustTooltipOnZoom}
      >
        <button
          className={classes.roundButton}
          onClick={() => {
            if (isPreviewButtonDisabled) {
              return;
            }
            previewMeeting();
          }}
          disabled={isPreviewButtonDisabled}
          type="button"
        >
          <img src={eyeIcon} alt="Preview" />
        </button>
      </OverlayTrigger>

      <OverlayTrigger
        onToggle={adjustTooltipOnZoom}
        placement="top-start"
        overlay={
          <Tooltip>Upload to Library in order to share with others</Tooltip>
        }
      >
        <div>
          <FilledButton
            // disabled={isPublishButtonDisabled}
            isDisabled={isPublishButtonDisabled}
            width={237}
            onClick={() => {
              if (isPublishButtonDisabled) {
                return;
              }
              if (sections) {
                if (
                  sections.some(
                    (section) =>
                      section.type === 'video' || section.type === 'audio'
                  )
                ) {
                  setIsPublishMeetingModalVisible(true);
                } else {
                  clearTimeout(noContentWarningTimer);
                  setIsNoContentWarningPopupVisible(true);
                  // eslint-disable-next-line no-param-reassign
                  noContentWarningTimer = setTimeout(() => {
                    setIsNoContentWarningPopupVisible(false);
                  }, 3000);
                }
              }
            }}
          >
            Publish
          </FilledButton>
        </div>
      </OverlayTrigger>

      {fetchedMeeting?.MeetingUsers?.[0]?.userRole === 'contributor' && (
        <OverlayTrigger
          onToggle={adjustTooltipOnZoom}
          placement="top-start"
          overlay={
            <Tooltip>
              Notifies video owner of sections you&apos;ve added
            </Tooltip>
          }
        >
          <div>
            <FilledButton
              isDisabled={isSectionButtonDisabled}
              type="button"
              onClick={() => {
                if (isSectionButtonDisabled) {
                  return;
                }

                closeAssignedSection();
              }}
            >
              Section(s) Complete!
            </FilledButton>
          </div>
        </OverlayTrigger>
      )}
    </div>
  );
}
