import React, { useState, useEffect, useRef } from 'react';

import Modal from 'react-bootstrap/Modal';

import closeIcon from '../../assets/images/closeIcon.svg';
import classes from './styles.module.scss';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';

export default function FileUploadProgressModal({
  show,
  handleClose,
  uploadProgress,
  isSectionBeingCreated,
  setUploadProgress,
}) {
  const [displayProgress, setDisplayProgress] = useState(uploadProgress);
  const [isUploaded, setIsUploaded] = useState(false);

  const animationFrameRef = useRef(null);
  const currentProgressRef = useRef(uploadProgress);

  const isMobile = useCheckMobileScreen();

  // If not mobile, show the the finishing info after the upload is complete
  useEffect(() => {
    if (uploadProgress === 100 && !isMobile) {
      setTimeout(() => {
        setIsUploaded(true);
      }, 500);
    }
  }, [isMobile, uploadProgress]);

  // Close modal after section is created (for desktop only)
  useEffect(() => {
    if (isUploaded && !isMobile && !isSectionBeingCreated) {
      handleClose();
      setIsUploaded(false);
      setUploadProgress(0);
    }
  }, [
    isMobile,
    isUploaded,
    isSectionBeingCreated,
    handleClose,
    setUploadProgress,
  ]);

  useEffect(() => {
    // Keep the ref updated with the latest displayed progress
    currentProgressRef.current = displayProgress;
  }, [displayProgress]);

  useEffect(() => {
    // Animate the progress smoothly when uploadProgress prop changes
    function animate() {
      const diff = uploadProgress - currentProgressRef.current;
      if (Math.abs(diff) < 0.1) {
        setDisplayProgress(uploadProgress);
        currentProgressRef.current = uploadProgress;
      } else {
        const newVal = currentProgressRef.current + diff * 0.1;
        setDisplayProgress(newVal);
        currentProgressRef.current = newVal;
        animationFrameRef.current = requestAnimationFrame(animate);
      }
    }

    animationFrameRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationFrameRef.current);
  }, [uploadProgress]);

  return (
    <Modal
      show={show}
      centered
      contentClassName={classes.FileUploadProgressModal}
      dialogClassName={classes.dialog}
      backdropClassName={classes.backdrop}
    >
      <i className={classes.closeIcon} onClick={handleClose}>
        <img src={closeIcon} alt="Close" />
      </i>
      <div className={classes.content}>
        <h3>{isUploaded ? 'Uploaded' : 'Uploading'}</h3>
        <p>{isUploaded ? 'processing...' : 'keep tab open until complete'}</p>
        <div className={classes.progressContainer}>
          <div className={classes.progressBackground}>
            <div
              className={classes.progressBar}
              style={{ width: `${displayProgress}%` }}
            />
          </div>
          <span className={classes.percentage}>
            {Math.round(displayProgress)}%
          </span>
        </div>
      </div>
    </Modal>
  );
}
