import React, { useMemo } from 'react';

import DropdownMenu from '../../DropdownMenu';

import infoIcon from '../../../assets/images/my-meetings/info.svg';
import shareIcon from '../../../assets/images/my-meetings/share.svg';
import editIcon from '../../../assets/images/my-meetings/edit2.svg';
import deleteIcon from '../../../assets/images/my-meetings/delete.svg';
import downloadIcon from '../../../assets/images/my-meetings/download.svg';
import usersIcon from '../../../assets/images/my-meetings/users.svg';
import linkIcon from '../../../assets/images/my-meetings/link.svg';
import reportIcon from '../../../assets/images/my-meetings/report.svg';

import { useUiContext } from '../../../context/UiContext';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';

const ActionsMenu = ({
  handleDetails,
  handleDelete,
  handleEdit,
  handleShare,
  handleManageUsers,
  handleDownload,
  handleCopyLink,
  handleGetViewerReport,
  handleMoveToTrash,
  handleRestore,
  isNotAllowedToDelete = true,
  isNotAllowedToEdit = true,
  isNotAllowedToShare = true,
  isNotAllowedToDownload = true,
  isNotAllowedToCheckDetails = true,
  isNotAllowedToManageUsers = true,
  isNotAllowedToCopyLink = true,
  isNotAllowedToGetViewerReport = true,
  isTrashBin,
}) => {
  const { notifySuccess } = useUiContext();

  const isMobile = useCheckMobileScreen();

  const options = useMemo(() => {
    const actions = [
      {
        label: 'Share',
        onClick: () => handleShare(),
        icon: <img src={shareIcon} alt="Share" />,
        disabled: isNotAllowedToShare,
      },
      {
        label: 'Edit',
        onClick: () => handleEdit(),
        icon: <img src={editIcon} alt="Edit" />,
        disabled: isNotAllowedToEdit,
      },
      {
        label: 'Users',
        onClick: () => handleManageUsers(),
        icon: <img src={usersIcon} alt="Users" />,
        disabled: isNotAllowedToManageUsers,
      },
      {
        label: 'Details',
        onClick: () => handleDetails(),
        icon: <img src={infoIcon} alt="Details" style={{ left: 6 }} />,
        disabled: isNotAllowedToCheckDetails,
      },
      {
        label: 'Delete',
        onClick: () => handleMoveToTrash(),
        icon: <img src={deleteIcon} alt="Delete" />,
        disabled: isNotAllowedToDelete,
      },
      {
        label: 'Copy link',
        onClick: () => handleCopyLink(),
        icon: <img src={linkIcon} alt="Copy link" />,
        disabled: isNotAllowedToCopyLink,
      },
      {
        label: 'Viewer Report',
        onClick: () => handleGetViewerReport(),
        icon: <img src={reportIcon} alt="Viewer Report" />,
        disabled: isNotAllowedToGetViewerReport,
      },
      {
        label: 'Download',
        onClick: () => {
          handleDownload();
          notifySuccess(
            'Downloading started. Please wait for the download to complete.'
          );
        },
        icon: <img src={downloadIcon} alt="Download" style={{ left: -4 }} />,
        disabled: isNotAllowedToDownload,
      },
    ];

    if (isMobile) {
      return [actions[1], actions[4]];
    }

    return actions.filter((action) => action.label !== 'Edit');
  }, [
    isNotAllowedToShare,
    isNotAllowedToEdit,
    isNotAllowedToManageUsers,
    isNotAllowedToCheckDetails,
    isNotAllowedToDelete,
    isNotAllowedToCopyLink,
    isNotAllowedToGetViewerReport,
    isNotAllowedToDownload,
    isMobile,
    handleShare,
    handleEdit,
    handleManageUsers,
    handleDetails,
    handleMoveToTrash,
    handleCopyLink,
    handleGetViewerReport,
    handleDownload,
    notifySuccess,
  ]);

  const trashBinOptions = useMemo(() => {
    return [
      {
        label: 'Restore',
        onClick: () => handleRestore(),
        icon: <img src={linkIcon} alt="Copy link" />,
      },
      {
        label: 'Delete Permanently',
        onClick: () => handleDelete(),
        icon: <img src={deleteIcon} alt="Delete" />,
      },
    ];
  }, [handleDelete, handleRestore]);

  const currentOptions = isTrashBin ? trashBinOptions : options;

  return <DropdownMenu options={currentOptions} isTrashBin={isTrashBin} />;
};

export default ActionsMenu;
