/* eslint-disable no-param-reassign */
import { useState, useEffect, useCallback } from 'react';

import { useQuery } from 'react-query';

import UserService from '../services/UserService';
import { useMeetingViewStore } from '../store';
import { useUiContext } from '../context/UiContext';

export default function useViewMeetingSectionLogic({
  videoRef,
  section,
  isCurrent,
  setIsPlaying,
  shouldReturnToFulscreen,
  setShouldReturnToFulscreen,
  fullscreenContainerRef,
  setIsSignUpPopupVisible,
  setCurrentSectionIndex,
  currentSectionIndex,
  sectionCount,
}) {
  const [isQuestionModalVisible, setIsQuestionModalVisible] = useState(false);
  const [isViewImageSectionModalVisible, setIsViewImageSectionModalVisible] =
    useState(false);

  const { showViewerNotesModal } = useUiContext();

  const { data: user } = useQuery('me', UserService.getMe);

  const setMinSectionIndex = useMeetingViewStore(
    (state) => state.setMinSectionIndex
  );
  const timelineMapping = useMeetingViewStore((state) => state.timelineMapping);

  const isNonPlaybleSection =
    section.type !== 'video' && section.type !== 'audio';

  const switchToNextSectionDuringPlayback = useCallback(
    (sectionIndex) => {
      if (sectionIndex > sectionCount - 1 || sectionIndex < 0) {
        return;
      }
      setMinSectionIndex(sectionIndex);
      setCurrentSectionIndex(sectionIndex);
    },
    [sectionCount, setCurrentSectionIndex, setMinSectionIndex]
  );

  const showQuestionModal = useCallback(() => {
    if (!user) {
      setIsSignUpPopupVisible(true);
    } else {
      setIsQuestionModalVisible(true);
    }

    // Exit fullscreen mode if it's active
    if (document.fullscreenElement) {
      document.exitFullscreen();
      setShouldReturnToFulscreen(true);
    }
  }, [setIsSignUpPopupVisible, setShouldReturnToFulscreen, user]);

  const showViewImageSectionModal = useCallback(() => {
    setIsViewImageSectionModalVisible(true);

    // Exit fullscreen mode if it's active
    if (document.fullscreenElement) {
      document.exitFullscreen();
      setShouldReturnToFulscreen(true);
    }
  }, [setShouldReturnToFulscreen]);

  // If video was in fullscreen mode before opening the modal, return to fullscreen mode
  const returnToFullscreen = useCallback(() => {
    if (shouldReturnToFulscreen) {
      fullscreenContainerRef.current?.requestFullscreen?.();
      setShouldReturnToFulscreen(false);
    }
  }, [
    fullscreenContainerRef,
    setShouldReturnToFulscreen,
    shouldReturnToFulscreen,
  ]);

  // Stop the video when the section is current and non-playable
  useEffect(() => {
    if (isCurrent && isNonPlaybleSection && videoRef.current) {
      videoRef.current.pause();
      setIsPlaying(false);

      // Show the question modal if the section is a question
      if (section.type === 'question') {
        showQuestionModal();
      }

      // Show the notes modal if the section is a note
      if (section.type === 'note') {
        showViewerNotesModal(section.text, () => {
          switchToNextSectionDuringPlayback(currentSectionIndex + 1);

          returnToFullscreen();
        });
      }

      // Show the image modal if the section is an image
      if (section.type === 'image') {
        showViewImageSectionModal();
      }
    } else if (isCurrent && !isNonPlaybleSection && videoRef.current) {
      setIsPlaying(true);
      videoRef.current.play();
    }
  }, [
    currentSectionIndex,
    isCurrent,
    isNonPlaybleSection,
    returnToFullscreen,
    section.text,
    section.type,
    setCurrentSectionIndex,
    setIsPlaying,
    setMinSectionIndex,
    showQuestionModal,
    showViewImageSectionModal,
    showViewerNotesModal,
    switchToNextSectionDuringPlayback,
    videoRef,
  ]);

  const hideQuestionModal = useCallback(() => {
    setIsQuestionModalVisible(false);

    setTimeout(() => {
      switchToNextSectionDuringPlayback(currentSectionIndex + 1);

      returnToFullscreen();
    }, 200);
  }, [
    switchToNextSectionDuringPlayback,
    currentSectionIndex,
    returnToFullscreen,
  ]);

  const hideViewImageSectionModal = useCallback(() => {
    setIsViewImageSectionModalVisible(false);

    setTimeout(() => {
      switchToNextSectionDuringPlayback(currentSectionIndex + 1);

      returnToFullscreen();
    }, 200);
  }, [
    currentSectionIndex,
    returnToFullscreen,
    switchToNextSectionDuringPlayback,
  ]);

  // Switch to a specific section
  const switchToSection = useCallback(
    (sectionIndex) => {
      setMinSectionIndex(sectionIndex);
      setCurrentSectionIndex(sectionIndex);

      videoRef.current.currentTime = timelineMapping[sectionIndex].startTime;
    },
    [setCurrentSectionIndex, setMinSectionIndex, timelineMapping, videoRef]
  );

  return {
    isQuestionModalVisible,
    hideQuestionModal,
    switchToSection,
    isViewImageSectionModalVisible,
    hideViewImageSectionModal,
  };
}
