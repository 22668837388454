/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import classNames from 'classnames';

import QuestionModal from '../../../modals/QuestionModal';
import ViewImageSectionModal from '../../../modals/ViewImageSectionModal';

import classes from './styles.module.scss';
import adjustTooltipOnZoom from '../../../helpers/adjustTooltipOnZoom';
import audioPlaceholder from '../../../assets/images/section-menu/audio-placeholder.png';
import questionSection from '../../../assets/images/section-menu/question-section.png';
import noteIcon from '../../../assets/images/section-menu/notes.svg';
import useViewMeetingSectionLogic from '../../../hooks/useViewMeetingSectionLogic';

export default function Section({
  section,
  index,
  isCurrent,
  videoRef,
  setIsPlaying,
  shouldReturnToFulscreen,
  setShouldReturnToFulscreen,
  fullscreenContainerRef,
  setIsSignUpPopupVisible,
  setCurrentSectionIndex,
  currentSectionIndex,
  preview,
  refetchMeeting,
  sectionsPageCount,
  sectionCount,
}) {
  const {
    isQuestionModalVisible,
    hideQuestionModal,
    switchToSection,
    isViewImageSectionModalVisible,
    hideViewImageSectionModal,
  } = useViewMeetingSectionLogic({
    videoRef,
    section,
    isCurrent,
    setIsPlaying,
    shouldReturnToFulscreen,
    setShouldReturnToFulscreen,
    fullscreenContainerRef,
    setIsSignUpPopupVisible,
    setCurrentSectionIndex,
    currentSectionIndex,
    sectionsPageCount,
    sectionCount,
  });

  let sectionContent = (
    <div className={classes.sectionContent}>
      <video src={section.signedS3Url} />
    </div>
  );

  if (section.type === 'assigned-section') {
    return null;
  }

  if (section.type === 'audio') {
    sectionContent = (
      <div className={classes.sectionContent}>
        <img src={audioPlaceholder} alt="Audio" />
      </div>
    );
  }

  if (section.type === 'question') {
    sectionContent = (
      <div className={classes.sectionContent}>
        <img src={questionSection} alt="Question" />
      </div>
    );
  }

  if (section.type === 'note') {
    sectionContent = (
      <div
        className={classNames(
          classes.sectionContent,
          classes.noteSectionContent
        )}
      >
        <img src={noteIcon} alt="Note" style={{ width: 100, height: 'auto' }} />
      </div>
    );
  }

  if (section.type === 'image') {
    sectionContent = (
      <div className={classes.sectionContent}>
        <img src={section.images?.[0]?.signedS3Url} alt="" />
      </div>
    );
  }

  return (
    <li
      className={classNames(classes.Section, {
        [classes.current]: isCurrent,
      })}
      onClick={() => switchToSection(index)}
    >
      {sectionContent}
      <OverlayTrigger
        placement="top"
        onToggle={adjustTooltipOnZoom}
        overlay={<Tooltip>{section.title}</Tooltip>}
      >
        <div className={classes.title}>
          <span>{section.title}</span>
        </div>
      </OverlayTrigger>
      {section.type === 'question' && (
        <QuestionModal
          show={isQuestionModalVisible}
          handleClose={hideQuestionModal}
          question={section.Question}
          refetchMeeting={refetchMeeting}
          preview={preview}
        />
      )}
      {section.type === 'image' && (
        <ViewImageSectionModal
          show={isViewImageSectionModalVisible}
          handleClose={hideViewImageSectionModal}
          images={section.images}
          text={section.text}
        />
      )}
    </li>
  );
}
