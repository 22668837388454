import React, { useEffect, useCallback } from 'react';

import EmojiPickerWithButton from '../EmojiPickerWithButton';

import classes from './styles.module.scss';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import { STATIC_URL } from '../../constants/main';
import userIcon from '../../assets/images/usericon.webp';

export default function ReplyBox({
  textareaRef,
  user,
  commentText,
  setCommentText,
  createComment,
  setIsSignUpPopupVisible,
}) {
  const isMobile = useCheckMobileScreen();

  const adjustTextareaHeight = useCallback(() => {
    const textarea = textareaRef.current;

    textarea.style.height = '1px';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }, [textareaRef]);

  useEffect(() => {
    adjustTextareaHeight();
  }, [adjustTextareaHeight]);

  return (
    <div className={classes.ReplyBox}>
      {user?.photoPath ? (
        <div className={classes.userPhoto}>
          <img src={STATIC_URL + user.photoPath} alt="" />
        </div>
      ) : (
        <div className={classes.userPhoto}>
          <img src={userIcon} alt="" />
        </div>
      )}

      <textarea
        ref={textareaRef}
        className={classes.textarea}
        placeholder={
          isMobile ? 'Add a comment...' : 'Add a comment or ask a question...'
        }
        value={commentText}
        onKeyUp={adjustTextareaHeight}
        onChange={(event) => setCommentText(event.target.value)}
        onKeyDown={(event) => {
          if (!isMobile) {
            return;
          }

          if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            if (commentText.trim()) {
              createComment();
              setCommentText('');
            }
          }
        }}
      />

      <button
        type="button"
        className={classes.submitButton}
        onClick={() => {
          setCommentText('');

          if (!user) {
            setIsSignUpPopupVisible(true);
            return;
          }

          const textarea = textareaRef.current;
          textarea.style.height = '1px';

          createComment();
        }}
      >
        Post
      </button>

      <div className={classes.emojiButton}>
        <EmojiPickerWithButton
          setCommentText={setCommentText}
          pickerTop={isMobile ? -300 : ''}
        />
      </div>
    </div>
  );
}
