/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import classes from './styles.module.scss';
import deleteIcon from '../../../assets/images/create-meeting/deleteIcon.svg';

export default function File({ file, onDelete }) {
  const imgSrc = file.url ? file.url : URL.createObjectURL(file);
  return (
    <div className={classes.File}>
      <img
        src={deleteIcon}
        className={classes.deleteIcon}
        alt="Delete"
        onClick={file.url ? () => onDelete(file) : onDelete}
      />
      <img
        className={classes.image}
        src={imgSrc}
        alt="Uploaded file"
        onLoad={() => URL.revokeObjectURL(imgSrc)} // Revoke the object URL after the image is loaded
      />
      <div className={classes.fileName}>
        {file.name.length > 20 ? `${file.name.slice(0, 20)}...` : file.name}
      </div>
    </div>
  );
}
