/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState, useEffect } from 'react';

import useOnClickOutside from '../../../hooks/useOnClickOutside';
import classes from './styles.module.scss';
import useCheckMobileScreen from '../../../hooks/useCheckMobileScreen';

const EditableTitle = ({
  children: title,
  handleUpdateTitle,
  isEditable,
  isEditing,
  setIsEditing,
}) => {
  const [isEditingLocal, setIsEditingLocal] = useState(false);
  const [newTitle, setNewTitle] = useState(title);
  const [isEditButtonVisible, setIsEditButtonVisible] = useState(false);

  const inputRef = useRef(null);

  const isMobile = useCheckMobileScreen();

  const handleSubmit = async () => {
    try {
      await handleUpdateTitle(newTitle.trim());

      setIsEditing?.(false);
      setIsEditingLocal(false);
    } catch (error) {
      console.log(error);
    }
  };

  useOnClickOutside(inputRef, () => handleSubmit());

  // Focus input when editing
  useEffect(() => {
    if (isEditing || isEditingLocal) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 0);
    }
  }, [isEditing, isEditingLocal]);

  const handleClickTitle = (e) => {
    if (!isEditable) {
      return;
    }

    e.stopPropagation();

    if (!newTitle.trim().length) {
      setNewTitle(title);
    }

    if (setIsEditing) {
      setIsEditing(true);
    } else {
      setIsEditingLocal(true);
    }

    setTimeout(() => {
      inputRef.current.focus();
    }, 0);
  };

  const handleChangeTitle = (event) => {
    setNewTitle(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <div
      onMouseOver={() => setIsEditButtonVisible(true)}
      onMouseLeave={() => setIsEditButtonVisible(false)}
      className={classes.EditableTitle}
    >
      {isEditing || isEditingLocal ? (
        <input
          ref={inputRef}
          value={newTitle}
          onChange={handleChangeTitle}
          onClick={(e) => e.stopPropagation()}
          onKeyDown={handleKeyPress}
          type="text"
        />
      ) : (
        <>
          <span>{title}</span>
          {isEditable && isEditButtonVisible && !isMobile && (
            <button
              onClick={handleClickTitle}
              type="button"
              className={classes.editButton}
            />
          )}
        </>
      )}
    </div>
  );
};

export default EditableTitle;
