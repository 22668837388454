import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';

import NavBar from '../NavBar';

import classes from './styles.module.scss';

import logo from '../../../assets/images/snapsop3.jpg';
import collapse from '../../../assets/images/nav/collapse.svg';
import { useUiStore } from '../../../store';

let wasToggledAutomatically = false;

export default function Sidebar() {
  const isSidebarCollapsed = useUiStore((state) => state.isSidebarCollapsed);
  const toggleSidebar = useUiStore((state) => state.toggleSidebar);

  const isNarrowDesktop = useMediaQuery({ maxWidth: 1600 });

  useEffect(() => {
    if (isNarrowDesktop && !isSidebarCollapsed && !wasToggledAutomatically) {
      console.log('Toggling sidebar');
      toggleSidebar();
      wasToggledAutomatically = true;
    }
  }, [isNarrowDesktop, isSidebarCollapsed, toggleSidebar]);

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <nav
      className={classNames(classes.Sidebar, {
        [classes.collapsed]: isSidebarCollapsed,
      })}
      onClick={toggleSidebar}
    >
      <div className={classes.logoContainer}>
        <Link onClick={(event) => event.stopPropagation()} to="/">
          <img src={logo} alt="Logo" className={classes.logo} />
        </Link>
      </div>
      <NavBar />
      <div className={classes.collapseButtonContainer}>
        <button
          className={classes.collapseButton}
          // onClick={toggleSidebar}
          type="button"
        >
          <img src={collapse} alt="Collapse" />
        </button>
      </div>
    </nav>
  );
}
