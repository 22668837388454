/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useCallback, useEffect, useRef, memo } from 'react';

import classNames from 'classnames';
import SvgArrow from '../CreateMeetingFooter/SectionMenu/SvgArrow';

import Section from './Section';

import classes from './styles.module.scss';
import { useUiStore } from '../../store';
// import usePreviewSections from '../../hooks/usePreviewSections';

// const SECTION_WIDTH = 336;
const SECTION_WIDTH = 235;

let timer;

const areEqual = (prevProps, nextProps) => {
  // Object to store the changed props
  const changedProps = {};

  // Create a set of all keys in both previous and next props
  const allKeys = new Set([
    ...Object.keys(prevProps),
    ...Object.keys(nextProps),
  ]);

  // Iterate over all keys to check for differences
  allKeys.forEach((key) => {
    if (prevProps[key] !== nextProps[key]) {
      changedProps[key] = {
        previous: prevProps[key],
        next: nextProps[key],
      };
    }
  });

  // If there are any differences, log them and return false so that React.memo triggers a re-render.
  if (Object.keys(changedProps).length > 0) {
    console.log('Changed props:', changedProps);
    return false;
  }

  // Otherwise, props are equal and we skip re-rendering.
  return true;
};

function PreviewSections({
  videoRef,
  previewSectionsRef,
  setIsPlaying,
  currentSectionIndex,
  shouldReturnToFulscreen,
  setShouldReturnToFulscreen,
  fullscreenContainerRef,
  setIsSignUpPopupVisible,
  setCurrentSectionIndex,
  preview,
  refetchMeeting,
  sections,
}) {
  const [sectionsPageCount, setSectionsPageCount] = useState(0);
  const [currentSectionsPage, setCurrentSectionsPage] = useState(0);
  const [width, setWidth] = useState(0);

  const sectionsListContainerRef = useRef();
  const leftSwitchRef = useRef();
  const shouldScrollIntoView = useRef(true);

  const isSidebarCollapsed = useUiStore((state) => state.isSidebarCollapsed);

  const zoom = getComputedStyle(document.documentElement)
    .getPropertyValue('--zoom')
    .trim();
  const zoomedSectionWidth = SECTION_WIDTH * zoom;

  useEffect(() => {
    const getWidth = () => {
      const sidebarWidth = isSidebarCollapsed ? 126 * zoom : 373 * zoom;

      const w = window.innerWidth - sidebarWidth - 42 - 46;

      setWidth(w);
    };

    getWidth();

    window.addEventListener('resize', getWidth);

    return () => {
      window.removeEventListener('resize', getWidth);
    };
  }, [isSidebarCollapsed, zoom]);

  const sectionsCount = Math.floor(width / (zoomedSectionWidth + 40)) || 1;

  const scrollSections = (direction) => {
    shouldScrollIntoView.current = false;

    clearTimeout(timer);
    timer = setTimeout(() => {
      shouldScrollIntoView.current = true;
    }, 500);

    if (direction === 'right') {
      if (currentSectionsPage === 0) {
        return;
      }

      setCurrentSectionsPage((prevPage) => prevPage - 1);
    } else {
      if (sectionsPageCount === currentSectionsPage) {
        return;
      }

      setCurrentSectionsPage((prevPage) => prevPage + 1);
    }
  };

  const scrollCurrentSectionIntoView = useCallback(
    (order) => {
      // If the current section is before the visible range,
      // scroll back so that it is the first visible.
      if (order < currentSectionsPage) {
        setCurrentSectionsPage(order);
      }
      // If the current section is past the visible range,
      // scroll forward so that it becomes visible.
      else if (order >= currentSectionsPage + sectionsCount) {
        const newPage = Math.min(order - sectionsCount + 1, sectionsPageCount);
        setCurrentSectionsPage(newPage);
      }
    },
    [currentSectionsPage, sectionsCount, sectionsPageCount]
  );

  useEffect(() => {
    if (!shouldScrollIntoView.current) {
      return;
    }

    scrollCurrentSectionIntoView(currentSectionIndex);
  }, [currentSectionIndex, scrollCurrentSectionIntoView]);

  useEffect(() => {
    setSectionsPageCount(Math.max(sections.length - sectionsCount, 0));
  }, [sections.length, sectionsCount]);

  return (
    <div className={classes.sections} ref={previewSectionsRef}>
      <div
        className={classNames(classes.switchLeft, classes.switch)}
        onClick={() => scrollSections('right')}
        ref={leftSwitchRef}
      >
        <SvgArrow />
      </div>
      <div
        className={classNames(classes.switchRight, classes.switch)}
        onClick={() => scrollSections('left')}
      >
        <SvgArrow />
      </div>

      <div
        className={classes.sectionsListContainer}
        ref={sectionsListContainerRef}
      >
        <ul
          className={classes.sectionsList}
          style={{
            width: (SECTION_WIDTH + 40) * sectionsCount,
          }}
        >
          <div
            className={classes.inner}
            style={{
              left: currentSectionsPage * -(SECTION_WIDTH + 40),
            }}
          >
            {sections.map((section, index) => {
              const isCurrent = currentSectionIndex === index;

              return (
                <Section
                  key={section.id}
                  section={section}
                  index={index}
                  isCurrent={isCurrent}
                  videoRef={videoRef}
                  setIsPlaying={setIsPlaying}
                  shouldReturnToFulscreen={shouldReturnToFulscreen}
                  setShouldReturnToFulscreen={setShouldReturnToFulscreen}
                  fullscreenContainerRef={fullscreenContainerRef}
                  setIsSignUpPopupVisible={setIsSignUpPopupVisible}
                  setCurrentSectionIndex={setCurrentSectionIndex}
                  currentSectionIndex={currentSectionIndex}
                  preview={preview}
                  refetchMeeting={refetchMeeting}
                  sectionCount={
                    sections.filter((s) => s.type !== 'assigned-section').length
                  }
                />
              );
            })}
          </div>
        </ul>
      </div>
    </div>
  );
}

export default memo(PreviewSections, areEqual);
