import React, { useState } from 'react';

import moment from 'moment';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import EditableTitle from '../../Library/EditableTitle';
import TitleWithProgress from '../TitleWithProgress';
import ActionsMenu from '../../Library/ActionsMenu';
import TableRowAction from '../../Library/TableRowAction';

import downloadVideo from '../../../helpers/downloadVideo';
import { ORG_ROLES, MEETING_TABS } from '../../../constants/main';
import formatDuration from '../../../helpers/formatDuration';
import playIcon from '../../../assets/images/my-meetings/play.svg';
import videoIcon from '../../../assets/images/my-library/video.svg';
import deleteIcon from '../../../assets/images/delete.svg';
import { useUiStore } from '../../../store';
import adjustTooltipOnZoom from '../../../helpers/adjustTooltipOnZoom';

export default function Row({
  classes,
  meeting,
  isMobile,
  isOrganizationOwner,
  tab,
  user,
  handleUpdateMeeting,
  handlePlay,
  handleCopyFolderLink,
  handleMeetingClick,
  destinationMeetingId,
  handleDetails,
  handleDelete,
  setCurrentContentTitle,
  setCurrentMeetingId,
  setIsSummaryModalVisible,
}) {
  const [isEditingTitle, setIsEditingTitle] = useState(false);

  const navigate = useNavigate();

  const breadcrumbsOptions = useUiStore((state) => state.breadcrumbsOptions);
  const setBreadcrumbsOptions = useUiStore(
    (state) => state.setBreadcrumbsOptions
  );

  const isMeetingPublished = meeting.status === 'published';
  const isMeetingInProgress =
    meeting.status === 'in progress' ||
    (meeting.type === 'mobile uploads' && meeting.status === 'draft');

  const isMeetingExpired =
    meeting.expiresAt && new Date(meeting.expiresAt) < new Date();

  const isNotAllowedToDownload =
    !isMeetingPublished ||
    (user?.orgRole?.access !== ORG_ROLES.OWNER && !isOrganizationOwner);

  const isNotAllowedToView =
    !isMeetingPublished || (isMeetingExpired && meeting.userId !== user?.id);

  const isNotAllowedToEdit =
    (meeting.userId !== user?.id &&
      meeting.userRole !== 'contributor' &&
      meeting.userRole !== 'contributor-closed' &&
      !isOrganizationOwner) ||
    meeting.status === 'in progress' ||
    (tab !== MEETING_TABS.MY_VIDEOS && tab !== MEETING_TABS.MOBILE_UPLOADS);

  const isNotAllowedToShare =
    meeting.userId !== user?.id || !isMeetingPublished;

  const isNotAllowedToCopyLink =
    meeting.userId !== user?.id || !isMeetingPublished;

  const isNotAllowedToGetViewerReport =
    !isMeetingPublished || meeting.userRole === 'viewer';

  const duration = meeting.duration && formatDuration(meeting.duration);

  const lastPublishedDate = isMeetingPublished
    ? moment(meeting.updatedAt).format('MMM D, YYYY')
    : 'Not Published';

  const uploadedDate = moment(meeting.createdAt).format('MMM D, YYYY');

  const title = (
    <EditableTitle
      handleUpdateTitle={(newTitle) =>
        handleUpdateMeeting(meeting.id, newTitle)
      }
      isEditable={!isNotAllowedToEdit}
      isEditing={isEditingTitle}
      setIsEditing={setIsEditingTitle}
    >
      {meeting.title}
    </EditableTitle>
  );

  const playButton = (
    <button
      className={classes.playButton}
      disabled={isNotAllowedToView}
      type="button"
      onClick={(event) => {
        event.stopPropagation();
        handlePlay(meeting.id);
      }}
    >
      <img src={playIcon} alt="Play" />
    </button>
  );

  return (
    <tr
      onClick={() => handleMeetingClick(meeting.id)}
      key={meeting.id}
      className={cn({
        [classes.clickable]: destinationMeetingId,
      })}
    >
      <td className={classes.title}>
        <div className={classes.left}>
          <div className={classes.roundIcon}>
            <img className={classes.videoIcon} src={videoIcon} alt="Video" />
          </div>

          <TitleWithProgress
            classes={classes}
            title={title}
            isMeetingPublished={!isMeetingInProgress}
            lastPublishedDate={lastPublishedDate}
            processing={{
              step: meeting?.type === 'saved' ? 'Processing' : 'Publishing',
              percentage: 10,
            }}
            meetingId={meeting.id}
          />
        </div>

        <div className={classes.right}>
          {playButton}
          {!isMobile && (
            <button
              onClick={(event) => {
                event.stopPropagation();
                handleDelete(meeting.id);
              }}
              type="button"
            >
              <img src={deleteIcon} alt="Delete" />
            </button>
          )}
        </div>
      </td>
      <td className={classes.owner}>
        {meeting.userId === user?.id
          ? 'Me'
          : meeting.ownerName || meeting.Owner?.name}
      </td>
      <td className={classes.status}>{uploadedDate}</td>
      <td className={classes.duration}>
        {isNotAllowedToView ? (
          <OverlayTrigger
            onToggle={adjustTooltipOnZoom}
            placement="left"
            overlay={
              <Tooltip>
                This video is not yet published. To preview, click on the three
                dots to the left and select EDIT and then PREVIEW.
              </Tooltip>
            }
          >
            {playButton}
          </OverlayTrigger>
        ) : (
          playButton
        )}
        {isNotAllowedToView ? (
          <span className={classes.noDuration}>{duration || 'NA'}</span>
        ) : (
          duration
        )}
      </td>
      {!isMobile && (
        <td className={classes.actions}>
          <TableRowAction
            handleDelete={() => handleDelete(meeting.id)}
            handleEdit={() => {
              setBreadcrumbsOptions([
                ...breadcrumbsOptions,
                {
                  label: meeting.title,
                },
              ]);
              navigate(`/my-videos/${meeting.id}/edit`);
            }}
          />
        </td>
      )}
      <td
        className={cn({
          [classes.buttons]: !isMobile,
          [classes.mobileActionsMenu]: isMobile,
        })}
      >
        <div className={classes.buttonsContainer}>
          <div className={classes.actionsContainer}>
            <ActionsMenu
              data={meeting}
              handleDetails={() => handleDetails(meeting)}
              handleMoveToTrash={() => handleDelete(meeting.id)}
              handleEdit={() => {
                // navigate(`/my-videos/${meeting.id}/edit`)
                setIsEditingTitle(true);
              }}
              handleShare={() =>
                navigate(`/my-videos/${meeting.id}/edit?share=true`)
              }
              handleDownload={() => downloadVideo(meeting)}
              handleGetViewerReport={() => {
                setCurrentContentTitle(meeting.title);
                setCurrentMeetingId(meeting.id);
                setIsSummaryModalVisible(true);
              }}
              handleCopyLink={() =>
                handleCopyFolderLink(meeting.folderId, meeting.id)
              }
              isNotAllowedToDelete={false}
              isNotAllowedToEdit={isNotAllowedToEdit}
              isNotAllowedToShare={isNotAllowedToShare}
              isNotAllowedToDownload={isNotAllowedToDownload}
              isNotAllowedToCheckDetails={false}
              isNotAllowedToGetViewerReport={isNotAllowedToGetViewerReport}
              isNotAllowedToCopyLink={isNotAllowedToCopyLink}
            />
          </div>
        </div>
      </td>
    </tr>
  );
}
