/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import classNames from 'classnames';

import QuestionModal from '../../../modals/QuestionModal';
import ViewImageSectionModal from '../../../modals/ViewImageSectionModal';

import classes from './styles.module.scss';
import audioPlaceholder from '../../../assets/images/section-menu/audio-placeholder.png';
import questionSection from '../../../assets/images/section-menu/question-section.png';
import noteIcon from '../../../assets/images/section-menu/notes.svg';
import useViewMeetingSectionLogic from '../../../hooks/useViewMeetingSectionLogic';

export default function Section({
  section,
  index,
  isCurrent,
  videoRef,
  setIsPlaying,
  shouldReturnToFulscreen,
  setShouldReturnToFulscreen,
  fullscreenContainerRef,
  setIsSignUpPopupVisible,
  setCurrentSectionIndex,
  currentSectionIndex,
  preview,
  refetchMeeting,
  sectionsPageCount,
  sectionCount,
  currentSectionRef,
}) {
  const {
    isQuestionModalVisible,
    hideQuestionModal,
    switchToSection,
    isViewImageSectionModalVisible,
    hideViewImageSectionModal,
  } = useViewMeetingSectionLogic({
    videoRef,
    section,
    isCurrent,
    setIsPlaying,
    shouldReturnToFulscreen,
    setShouldReturnToFulscreen,
    fullscreenContainerRef,
    setIsSignUpPopupVisible,
    setCurrentSectionIndex,
    currentSectionIndex,
    sectionsPageCount,
    sectionCount,
  });

  if (section.type === 'assigned-section') {
    return null;
  }

  let sectionContent = (
    <div className={classes.sectionContent}>
      <video src={section.signedS3Url} />
    </div>
  );

  if (section.type === 'audio') {
    sectionContent = (
      <div className={classes.sectionContent}>
        <img src={audioPlaceholder} alt="Audio" />
      </div>
    );
  }

  if (section.type === 'question') {
    sectionContent = (
      <div className={classes.sectionContent}>
        <img src={questionSection} alt="Question" />
      </div>
    );
  }

  if (section.type === 'note') {
    sectionContent = (
      <div
        className={classNames(
          classes.sectionContent,
          classes.noteSectionContent
        )}
      >
        <img src={noteIcon} alt="Note" style={{ objectFit: 'contain' }} />
      </div>
    );
  }

  if (section.type === 'image') {
    sectionContent = (
      <div className={classes.sectionContent}>
        <img src={section.images?.[0]?.signedS3Url} alt="" />
      </div>
    );
  }

  return (
    <li
      key={section.id}
      className={classNames({
        [classes.current]: isCurrent,
      })}
      onClick={() => switchToSection(index)}
      ref={isCurrent ? currentSectionRef : null}
    >
      {sectionContent}

      <div
        className={classNames(classes.title, {
          [classes.current]: isCurrent,
        })}
      >
        <span>{section.title}</span>
      </div>
      {section.type === 'question' && (
        <QuestionModal
          show={isQuestionModalVisible}
          handleClose={hideQuestionModal}
          question={section.Question}
          refetchMeeting={refetchMeeting}
          preview={preview}
        />
      )}
      {section.type === 'image' && (
        <ViewImageSectionModal
          show={isViewImageSectionModalVisible}
          handleClose={hideViewImageSectionModal}
          images={section.images}
          text={section.text}
        />
      )}
    </li>
  );
}
