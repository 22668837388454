import React, { useState, useCallback, useEffect } from 'react';

import Header from './Header';
import Controls from './Controls';
import PublishingOverlay from './PublishingOverlay';

import classes from './styles.module.scss';

let animationId;

export default function VideoPlayerOverlay({
  meetingTitle,
  setIsShareMeetingModalVisible,
  videoRef,
  videoDuration,
  isDraggingProgressBar,
  progressBarRef,
  mouseDownHandler,
  isVideoReady,
  isAudio,
  stopVideoPlayer,
  pauseVideoPlayer,
  startVideoPlayer,
  startTimer,
  isRecording,
  createSectionMode,
  setAudioImage,
  isPlaying,
  arePlayerButtonsVisible,
  videoSectionPlayerRef,
  isPublishing,
  viewOnly,
  onBackClick,
  fullscreenContainerRef,
  onRewind,
}) {
  const [videoProgress, setVideoProgress] = useState(0);

  const animateProgressBar = useCallback(() => {
    const newLocal =
      (100 / videoSectionPlayerRef.current?.duration) *
      videoSectionPlayerRef.current?.currentTime;
    setVideoProgress(newLocal);

    animationId = requestAnimationFrame(animateProgressBar);
  }, [videoSectionPlayerRef]);

  useEffect(() => {
    if (isPlaying) {
      animateProgressBar();
    }
  }, [isPlaying, animateProgressBar]);

  return (
    <div
      style={{
        pointerEvents: isPublishing ? 'none' : 'auto',
      }}
      className={classes.VideoPlayerOverlay}
      onClick={() => {
        if (isPlaying) {
          pauseVideoPlayer();
        } else {
          startVideoPlayer();
          //   animateProgressBar();
        }
      }}
    >
      <Header
        isPublishing={isPublishing}
        meetingTitle={meetingTitle}
        setIsShareMeetingModalVisible={setIsShareMeetingModalVisible}
        viewOnly={viewOnly}
        onBackClick={onBackClick}
      />
      <Controls
        videoProgress={videoProgress}
        animateProgressBar={animateProgressBar}
        arePlayerButtonsVisible={arePlayerButtonsVisible}
        videoRef={videoRef}
        videoDuration={videoDuration}
        isDraggingProgressBar={isDraggingProgressBar}
        progressBarRef={progressBarRef}
        mouseDownHandler={mouseDownHandler}
        isVideoReady={isVideoReady}
        isAudio={isAudio}
        stopVideoPlayer={stopVideoPlayer}
        pauseVideoPlayer={pauseVideoPlayer}
        startVideoPlayer={startVideoPlayer}
        startTimer={startTimer}
        isRecording={isRecording}
        createSectionMode={createSectionMode}
        setAudioImage={setAudioImage}
        isPlaying={isPlaying}
        videoSectionPlayerRef={videoSectionPlayerRef}
        animationId={animationId}
        meetingTitle={meetingTitle}
        fullscreenContainerRef={fullscreenContainerRef}
        onRewind={onRewind}
      />
      {isPublishing && <PublishingOverlay />}
    </div>
  );
}
