import React from 'react';

import { useQuery, useMutation } from 'react-query';

import Library from '../../components/Library';
import FolderService from '../../services/FolderService';
import MeetingService from '../../services/MeetingService';
import useFetchingState from '../../components/Library/useFetchingState';

const itemsRange = 999999;

export default function TrashBinPage() {
  const {
    page,
    setPage,
    searchInput,
    setSearchInput,
    sortField,
    setSortField,
    sortOrder,
    setSortOrder,
    debouncedSearchInput,
    isShare,
    id,
    isMobile,
  } = useFetchingState();

  const {
    data: folders,
    refetch: refetchFolders,
    isFetching: areFoldersFetching,
  } = useQuery(
    ['folders', page, debouncedSearchInput, sortField, sortOrder],
    () =>
      FolderService.getDeletedFolders({
        page,
        search: debouncedSearchInput,
        limit: itemsRange,
        sortField,
        sortOrder,
        deleted: true,
      }),
    {
      keepPreviousData: true,
      enabled: !id,
    }
  );

  const { data: deletedMeetingsData, refetch: refetchDeletedMeetings } =
    useQuery(
      [
        'meetings',
        page,
        debouncedSearchInput,
        itemsRange,
        sortField,
        sortOrder,
      ],
      () =>
        MeetingService.getMeetings({
          page,
          search: debouncedSearchInput,
          limit: itemsRange,
          type: 'my videos',
          sortField,
          sortOrder,
          deleted: true,
        }),
      {
        keepPreviousData: true,
        //   refetchInterval: processingMeetings.length ? 2500 : 0,
      }
    );

  const deletedMeetings = deletedMeetingsData?.rows || deletedMeetingsData;

  const { mutate: deleteFolderMutation } = useMutation(
    FolderService.deleteFolder,
    {
      onSuccess: () => {
        refetchFolders();
      },
    }
  );

  const { mutate: toggleFolderTrashStatusMutation } = useMutation(
    FolderService.toggleFolderTrashStatus,
    {
      onSuccess: () => {
        refetchFolders();
        refetchDeletedMeetings();
      },
    }
  );

  const { mutate: toggleMeetingTrashStatusMutation } = useMutation(
    MeetingService.toggleMeetingTrashStatus,
    {
      onSuccess: () => {
        refetchDeletedMeetings();
      },
    }
  );

  const { mutate: updateFolderMutation } = useMutation(
    FolderService.updateFolder,
    {
      onSuccess: () => {
        refetchFolders();
        refetchDeletedMeetings();
      },
    }
  );

  const { mutate: deleteMeetingMutation } = useMutation(
    MeetingService.deleteMeeting,
    {
      onSuccess: () => {
        refetchDeletedMeetings();
      },
    }
  );

  return (
    <Library
      searchInput={searchInput}
      setSearchInput={setSearchInput}
      isMobile={isMobile}
      page={page}
      setPage={setPage}
      sortField={sortField}
      setSortField={setSortField}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
      debouncedSearchInput={debouncedSearchInput}
      refetchFolders={refetchFolders}
      //  refetchFolder={refetchFolder}
      areFoldersFetching={areFoldersFetching}
      //  isFolderFetching={isFolderFetching}
      folders={folders}
      openedFolder={null}
      id={id}
      isShare={isShare}
      itemsRange={itemsRange}
      deleteFolderMutation={deleteFolderMutation}
      moveToTrashMeetingMutation={toggleMeetingTrashStatusMutation}
      restoreMeetingMutation={toggleMeetingTrashStatusMutation}
      updateFolderMutation={updateFolderMutation}
      breadcrumbLabel="Trash Bin"
      breadcrumbTo="/trash-bin"
      deletedMeetings={deletedMeetings}
      deleteMeetingMutation={deleteMeetingMutation}
      restoreFolderMutation={toggleFolderTrashStatusMutation}
    />
  );
}
