import { create } from 'zustand';

export const useCreateMeetingStore = create((set) => ({
  prevSectionId: null,
  isSectionAutoScrollDisabled: true,
  hasClickedOnSection: false,

  isUploadFilesModalVisible: false,

  setPrevSectionId: (prevSectionId) => set({ prevSectionId }),
  setIsSectionAutoScrollDisabled: (isSectionAutoScrollDisabled) =>
    set({ isSectionAutoScrollDisabled }),
  setHasClickedOnSection: (hasClickedOnSection) => set({ hasClickedOnSection }),

  setIsUploadFilesModalVisible: (isUploadFilesModalVisible) =>
    set({ isUploadFilesModalVisible }),
}));

export const useUiStore = create((set) => ({
  breadcrumbsOptions: [],
  isSidebarCollapsed: false,
  sectionsListTranslateX: 0,

  setBreadcrumbsOptions: (breadcrumbsOptions) => set({ breadcrumbsOptions }),
  toggleSidebar: () =>
    set((state) => ({ isSidebarCollapsed: !state.isSidebarCollapsed })),
  setSectionsListTranslateX: (sectionsListTranslateX) =>
    set({ sectionsListTranslateX }),
}));

export const useMeetingViewStore = create((set) => ({
  // minSectionIndex accounts for non-playable sections with zero duration.
  // These sections share the same startTime as the nearest following playable section.
  // When the video is played, the non-playable section becomes active first.
  // However, if the user manually clicks on a section, we must prevent the previous non-playable section from becoming active automatically.
  // Setting minSectionIndex to the index of the clicked section ensures that the clicked section becomes active.
  minSectionIndex: 0,
  timelineMapping: [],

  setMinSectionIndex: (minSectionIndex) => set({ minSectionIndex }),
  setTimelineMapping: (timelineMapping) => set({ timelineMapping }),
}));
