/* eslint-disable no-shadow */
import React, { useState, useRef, useEffect } from 'react';

import Modal from 'react-bootstrap/Modal';

import classNames from 'classnames';
import ConfirmButton from '../../components/Buttons/ConfirmButton';
import File from './File';
import RoundedInput from '../../components/Inputs/RoundedInput';

import classes from './styles.module.scss';
import closeIcon from '../../assets/images/closeIcon.svg';
import uploadIcon from '../../assets/images/create-meeting/upload.svg';

export default function UploadFilesModal({
  show,
  handleClose,
  onSubmit,
  initialFiles,
  withText,
  initialText,
}) {
  const [files, setFiles] = useState([]);
  const [prevFiles, setPrevFiles] = useState([]);
  const [deletedPrevFilesIds, setDeletedPrevFilesIds] = useState([]);
  const [description, setDescription] = useState('');

  const inputRef = useRef();

  const openFileDialog = () => {
    inputRef.current.click();
  };

  const addFiles = (event) => {
    const addedFiles = Array.from(event.target.files);
    const filteredFiles = addedFiles
      .filter((file) => {
        const fileType = file.type.split('/')[0];
        return fileType === 'image';
      })
      .filter((file) => {
        return (
          !files.some((prevFile) => prevFile.name === file.name) &&
          !prevFiles.some((pFile) => pFile.name === file.name)
        );
      });

    setFiles((prev) => {
      return [...prev, ...filteredFiles];
    });
  };

  useEffect(() => {
    if (!show) {
      setFiles([]);
      setPrevFiles([]);
      setDeletedPrevFilesIds([]);
      setDescription('');
    }
  }, [show]);

  useEffect(() => {
    if (initialFiles && initialFiles.length > 0) {
      setPrevFiles(initialFiles);
    }

    setDescription(initialText);
  }, [initialFiles, initialText]);

  const zoom =
    getComputedStyle(document.documentElement)
      .getPropertyValue('--zoom')
      .trim() || 0.9;

  const noFiles =
    (!files || files.length === 0) && (!prevFiles || prevFiles.length === 0);

  return (
    <Modal
      show={show}
      centered
      contentClassName={classes.ViewerNotesModal}
      dialogClassName={classes.dialog}
      backdropClassName={classes.backdrop}
    >
      <header className={classes.header}>
        <h1 className={classes.title}>Upload Files</h1>
        <button
          className={classes.closeButton}
          type="button"
          onClick={handleClose}
        >
          <img src={closeIcon} alt="Close" />
        </button>
      </header>
      <div
        className={classNames(classes.attachments, {
          [classes.withText]: withText,
        })}
      >
        {prevFiles && prevFiles.length > 0
          ? prevFiles.map((file) => {
              return (
                <File
                  key={file.name}
                  file={file}
                  onDelete={() => {
                    setPrevFiles((prev) =>
                      prev.filter((prevFile) => prevFile.id !== file.id)
                    );
                    setDeletedPrevFilesIds((prev) => {
                      return [...prev, file.id];
                    });
                  }}
                />
              );
            })
          : null}
        {files.length
          ? files.map((file) => {
              return (
                <File
                  key={file.name}
                  file={file}
                  onDelete={() => {
                    setPrevFiles((prev) =>
                      prev.filter((prevFile) => prevFile.name !== file.name)
                    );
                  }}
                />
              );
            })
          : null}
        {noFiles && (
          <div className={classes.uploader} onClick={openFileDialog}>
            <img src={uploadIcon} alt="Upload" />
            <p>Import files to attach within your video</p>
          </div>
        )}
      </div>
      {withText && (
        <div className={classes.inputContainer}>
          <RoundedInput
            label="Description"
            value={description}
            onChange={(event) => {
              setDescription(event.target.value);
            }}
          />
        </div>
      )}
      <footer className={classes.footer}>
        <p className={classes.info}>Only images allowed</p>
        <div className={classes.buttons}>
          <button
            type="button"
            className={classes.importButton}
            onClick={openFileDialog}
          >
            Import
          </button>
          <ConfirmButton
            style={{ height: 70 / zoom }}
            width={70 / zoom + 10}
            onClick={() => {
              if (files.length || deletedPrevFilesIds.length || description) {
                onSubmit({
                  files,
                  deletedInitialFilesIds: deletedPrevFilesIds,
                  text: description,
                });
              }

              handleClose();
            }}
          />
        </div>
      </footer>
      <input
        onChange={addFiles}
        ref={inputRef}
        type="file"
        accept="image/*"
        multiple
        style={{
          display: 'none',
        }}
      />
    </Modal>
  );
}
