/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';

import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import classes from './styles.module.scss';
import cameraIcon from '../../../../assets/images/section-menu/camera.svg';
import selfIcon from '../../../../assets/images/section-menu/self.svg';
import desktopIcon from '../../../../assets/images/section-menu/desktop.svg';
import questionIcon from '../../../../assets/images/section-menu/question.svg';
import assignIcon from '../../../../assets/images/section-menu/assign.svg';
import audioIcon from '../../../../assets/images/section-menu/audio.svg';
import notesIcon from '../../../../assets/images/section-menu/notes.svg';
import importIcon from '../../../../assets/images/section-menu/import.svg';

import { useUiContext } from '../../../../context/UiContext';
import { useUiStore } from '../../../../store';

export default function Menu({
  onCamera,
  onScreen,
  isScreenEnabled,
  isCameraEnabled,
  isRecording,
  showPlaceholder,
  onQuestion,
  setIsQuestionFormVisible,
  isQuestionFormVisible,
  onAssignSection,
  isAssignSectionFormVisible,
  meeting,
  meetingTitle,
  setIsNoTitleWarningVisible,
  isAudioEnabled,
  onAudio,
  sections,
  createMeeting,
  folderId,
  setMeetingId,
  refetchSections,
  createSection,
  updateTextSection,
  prevSectionId,
  hidePlaceholder,
  plusSignRef,
  isMenuVisible,
  setIsMenuVisible,
  menuRef,
  setIsUploadFilesModalVisible,
  setIsImportFileModalVisible,
}) {
  const [isRecordSubmenuVisible, setIsRecordSubmenuVisible] = useState(false);
  const [isEnoughSpaceForSubmenu, setIsEnoughSpaceForSubmenu] = useState(true);
  const [leftPosition, setLeftPosition] = useState(0);
  const [bottomPosition, setBottomPosition] = useState(0);

  const navigate = useNavigate();

  const { showMeetingNotesModal } = useUiContext();
  const sectionsListTranslateX = useUiStore(
    (state) => state.sectionsListTranslateX
  );

  const showRecordSubmenu = () => {
    setIsRecordSubmenuVisible(true);
  };

  const hideRecordSubmenu = () => {
    setIsRecordSubmenuVisible(false);
  };

  // On menu render check if there is enough space for submenu
  useEffect(() => {
    if (!menuRef.current) {
      return;
    }

    const menuCoords = menuRef.current.getBoundingClientRect();

    if (menuCoords.left < 242) {
      setIsEnoughSpaceForSubmenu(false);
    } else {
      setIsEnoughSpaceForSubmenu(true);
    }
  }, [isMenuVisible, leftPosition, menuRef]);

  // On menu render set left and bottom position for menu based on plus sign position
  useEffect(() => {
    if (!plusSignRef.current) {
      return;
    }

    const zoom = getComputedStyle(document.documentElement)
      .getPropertyValue('--zoom')
      .trim();

    const triggerCoords = plusSignRef.current.getBoundingClientRect();

    setLeftPosition(triggerCoords.left / zoom);
    setBottomPosition(window.innerHeight / zoom - triggerCoords.bottom / zoom);
  }, [isMenuVisible, plusSignRef, sectionsListTranslateX]);

  const lastSection = sections?.[sections.length - 1];

  const isMenuBlocked =
    isCameraEnabled ||
    isRecording ||
    isScreenEnabled ||
    isAudioEnabled ||
    (isQuestionFormVisible && lastSection?.placeholderType === 'question') ||
    isAssignSectionFormVisible ||
    meeting?.userRole === 'contributor-closed';

  const isAssignSectionButtonDisabled = meeting?.userRole === 'contributor';

  // TODO: Split menu items into separate components
  return (
    <>
      {isMenuVisible &&
        createPortal(
          <div
            className={classes.menu}
            ref={menuRef}
            style={{ left: leftPosition, bottom: bottomPosition }}
            onClick={(event) => event.stopPropagation()}
          >
            <ul>
              <li
                className={classNames({
                  [classes.disabled]: isMenuBlocked,
                })}
                onMouseEnter={showRecordSubmenu}
                onMouseLeave={hideRecordSubmenu}
              >
                <span className={classes.iconContainer}>
                  <img src={cameraIcon} alt="Camera" />
                </span>
                Record options
                {isRecordSubmenuVisible && (
                  <div
                    className={classNames(classes.subMenu, {
                      [classes.right]: !isEnoughSpaceForSubmenu,
                    })}
                    onMouseEnter={showRecordSubmenu}
                    onMouseLeave={hideRecordSubmenu}
                  >
                    <ul>
                      <li
                        className={classNames({
                          [classes.disabled]: isMenuBlocked,
                        })}
                        onClick={() => {
                          if (isMenuBlocked) {
                            return;
                          }

                          if (!meetingTitle.trim()) {
                            setIsNoTitleWarningVisible(true);
                            setIsMenuVisible(false);
                            return;
                          }

                          if (!isCameraEnabled && !isRecording) {
                            onCamera();
                          }

                          if (!isRecording) {
                            showPlaceholder('', 'video', prevSectionId);
                          }

                          setIsMenuVisible(false);
                          setIsQuestionFormVisible(false);
                        }}
                      >
                        <span className={classes.iconContainer}>
                          <img src={selfIcon} alt="Self" />
                        </span>
                        Record self
                      </li>
                      <li
                        className={classNames({
                          [classes.disabled]: isMenuBlocked,
                        })}
                        onClick={() => {
                          if (isMenuBlocked) {
                            return;
                          }

                          if (!meetingTitle.trim()) {
                            setIsNoTitleWarningVisible(true);
                            setIsMenuVisible(false);
                            return;
                          }

                          if (!isScreenEnabled && !isRecording) {
                            onScreen();
                          }
                          if (!isRecording) {
                            showPlaceholder('', 'video', prevSectionId);
                          }
                          setIsMenuVisible(false);
                          setIsQuestionFormVisible(false);
                        }}
                      >
                        <span className={classes.iconContainer}>
                          <img src={desktopIcon} alt="Desktop" />
                        </span>
                        Record screen
                      </li>
                      <li
                        className={classNames({
                          [classes.disabled]: isMenuBlocked,
                        })}
                        onClick={() => {
                          if (isMenuBlocked) {
                            return;
                          }

                          if (!meetingTitle.trim()) {
                            setIsNoTitleWarningVisible(true);
                            setIsMenuVisible(false);
                            return;
                          }

                          if (!isAudioEnabled && !isRecording) {
                            onAudio();
                          }
                          if (!isRecording) {
                            showPlaceholder('', 'audio', prevSectionId);
                          }
                          setIsMenuVisible(false);
                          setIsQuestionFormVisible(false);
                        }}
                      >
                        <span className={classes.iconContainer}>
                          <img
                            src={audioIcon}
                            alt="Audio"
                            style={{ width: 47 }}
                          />
                        </span>
                        Record audio
                      </li>
                    </ul>
                  </div>
                )}
              </li>
              <li
                className={classNames({
                  [classes.disabled]: isMenuBlocked,
                })}
                onClick={() => {
                  if (isMenuBlocked) {
                    return;
                  }

                  if (!meetingTitle.trim()) {
                    setIsNoTitleWarningVisible(true);
                    setIsMenuVisible(false);
                    return;
                  }

                  onQuestion();
                  showPlaceholder('', 'question', prevSectionId);
                  setIsMenuVisible(false);
                }}
              >
                <span className={classes.iconContainer}>
                  <img
                    src={questionIcon}
                    alt="Question"
                    style={{ position: 'relative', left: 8 }}
                  />
                </span>
                <div className={classes.nameContainer}>Add a question</div>
              </li>
              <li
                className={classNames({
                  [classes.disabled]:
                    isMenuBlocked || isAssignSectionButtonDisabled,
                })}
                onClick={() => {
                  if (isMenuBlocked || isAssignSectionButtonDisabled) {
                    return;
                  }

                  if (!meetingTitle.trim()) {
                    setIsNoTitleWarningVisible(true);
                    setIsMenuVisible(false);
                    return;
                  }

                  onAssignSection();
                  showPlaceholder('', 'assign', prevSectionId);
                  setIsMenuVisible(false);
                }}
              >
                <span className={classes.iconContainer}>
                  <img src={assignIcon} alt="Assign" />
                </span>
                <div className={classes.nameContainer}>Assign a Section</div>
              </li>
              <li
                className={classNames({
                  [classes.disabled]: isMenuBlocked,
                })}
                onClick={async () => {
                  if (isMenuBlocked) {
                    return;
                  }

                  if (!meetingTitle.trim()) {
                    setIsNoTitleWarningVisible(true);
                    setIsMenuVisible(false);
                    return;
                  }

                  const noteSectionCount = sections?.filter(
                    (section) => section.type === 'note'
                  ).length;

                  if (!meeting) {
                    const newMeeting = await createMeeting({
                      title: meetingTitle,
                      folderId,
                    });
                    const { id } = newMeeting;
                    setMeetingId(id);

                    navigate(`/my-videos/${id}/edit`, { replaceState: true });
                    showMeetingNotesModal({
                      refetchSections,
                      noteSectionCount,
                      createSection,
                      updateTextSection,
                      hidePlaceholder,
                    });

                    return;
                  }

                  showPlaceholder('', 'note', prevSectionId);
                  showMeetingNotesModal({
                    refetchSections,
                    noteSectionCount,
                    createSection,
                    updateTextSection,
                    hidePlaceholder,
                  });
                }}
              >
                <span className={classes.iconContainer}>
                  <img src={notesIcon} alt="Notes" />
                </span>
                Add Text
              </li>
              <li
                className={classNames({
                  [classes.disabled]: isMenuBlocked,
                })}
                onClick={() => {
                  if (isMenuBlocked) {
                    return;
                  }

                  setIsImportFileModalVisible(true);
                  setIsMenuVisible(false);
                }}
              >
                <span className={classes.iconContainer}>
                  <img
                    src={importIcon}
                    alt="Import file"
                    style={{ position: 'relative', left: -5 }}
                  />
                </span>
                <div className={classes.nameContainer}>Import file</div>
              </li>
              <li
                className={classNames({
                  [classes.disabled]: isMenuBlocked,
                })}
                onClick={() => {
                  if (isMenuBlocked) {
                    return;
                  }

                  setIsUploadFilesModalVisible(true);
                  setIsMenuVisible(false);
                }}
              >
                <span className={classes.iconContainer}>
                  <img
                    src={importIcon}
                    alt="Import file"
                    style={{ position: 'relative', left: -5 }}
                  />
                </span>
                <div className={classes.nameContainer}>Import image</div>
              </li>
            </ul>
          </div>,
          document.body
        )}
    </>
  );
}
