/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useRef, useContext, memo } from 'react';

import classNames from 'classnames';

import DeleteSectionIcon from '../DeleteSectionIcon';
import FavoritedPopup from './FavoritedPopup';

import noteIcon from '../../../assets/images/section-menu/notes.svg';
import saveIcon from '../../../assets/images/section-menu/star.svg';
import questionSection from '../../../assets/images/section-menu/question-section.png';
import audioPlaceholder from '../../../assets/images/section-menu/audio-placeholder.png';
import playIcon from '../../../assets/images/section-menu/play.svg';
import SectionService from '../../../services/SectionService';
import { ORG_ROLES } from '../../../constants/main';
import UserIcon from './UserIcon';
import { UiContext } from '../../../context/UiContext';
import { useCreateMeetingStore } from '../../../store';

function Section({
  refetchSections,
  section,
  index,
  setVideoPlayerSource,
  setCurrentSectionId,
  setIsConfirmSectionRemovalVisible,
  classes,
  setIsQuestionFormVisible,
  setFetchedQuestionData,
  user,
  meeting,
  stopVideoPlayer,
  hideQuestionAndAssignSectionForms,
  hidePlaceholder,
  sectionTitle,
  setSectionTitle,
  createDefaultSectionName,
  isActive,
  // setIsSectionSavedModalVisible,
  setIsSectionCreationStopped,
  processingProgress,
  updateSectionTitle,
  updateTextSection,
  createSection,
  setIsNotAllowedToDeleteSectionMessageVisible,
}) {
  const [newSectionTitle, setNewSectionTitle] = useState('');
  const [shouldUpdateTitle, setShouldUpdateTitle] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [isFavoritedPopupVisible, setIsFavoritedPopupVisible] = useState(false);
  const [hasFavoriteButtonBeenClicked, setHasFavoriteButtonBeenClicked] =
    useState(false);

  const titleInputRef = useRef();
  const timer = useRef();
  const audioSectionContainerRef = useRef();
  const sectionRef = useRef();

  const { showMeetingNotesModal } = useContext(UiContext);

  const setHasClickedOnSection = useCreateMeetingStore(
    (state) => state.setHasClickedOnSection
  );
  const setIsUploadFilesModalVisible = useCreateMeetingStore(
    (state) => state.setIsUploadFilesModalVisible
  );

  let sectionContent = <video src={section.signedS3Url} />;

  // Set default title for the new section and focus on input
  useEffect(() => {
    if (section.path === 'placeholder') {
      const defaultTitle = createDefaultSectionName(section);

      setSectionTitle(defaultTitle);

      titleInputRef.current.focus();
    }
  }, [createDefaultSectionName, section, setSectionTitle]);

  useEffect(() => {
    if (section) {
      setNewSectionTitle(section.title);
      setShouldUpdateTitle(false);
    }
  }, [section]);

  if (section.path === 'placeholder') {
    sectionContent = (
      <>
        <span>{section.title}</span>
      </>
    );
  }

  useEffect(() => {
    clearTimeout(timer.current);

    timer.current = setTimeout(() => {
      if (!shouldUpdateTitle) {
        return;
      }

      if (newSectionTitle === section.title || !newSectionTitle.trim()) {
        return;
      }

      updateSectionTitle({ ...section, title: newSectionTitle });
    }, 1500);
  }, [newSectionTitle, section, shouldUpdateTitle, updateSectionTitle]);

  if (section.type === 'question') {
    sectionContent = (
      <div
        className={classes.questionSectionContent}
        onClick={() => {
          setFetchedQuestionData(section?.Question);
          setIsQuestionFormVisible(true);
        }}
      >
        <img src={questionSection} alt="question" />
      </div>
    );
  } else if (section.type === 'assigned-section') {
    sectionContent = (
      <div className={classes.assignedSectionContent}>
        <span>Section Assigned</span>
      </div>
    );
  } else if (section.type === 'audio') {
    sectionContent = (
      <div
        className={classes.audioBackgroundContainer}
        ref={audioSectionContainerRef}
      >
        <img src={audioPlaceholder} alt="Audio" />
      </div>
    );
  } else if (section.type === 'note') {
    sectionContent = (
      <div
        className={classes.noteSectionContent}
        onClick={() => {
          if (section) {
            showMeetingNotesModal({
              refetchSections,
              noteSectionCount: 0,
              noteSectionData: section,
              createSection,
              updateTextSection,
            });
          }
        }}
      >
        <img src={noteIcon} alt="Note" />
      </div>
    );
  } else if (section.type === 'image') {
    sectionContent = (
      <img
        src={section.images?.[0]?.signedS3Url}
        alt="Section"
        onClick={() => setIsUploadFilesModalVisible(true)}
      />
    );
  } else if (processingProgress.sectionId === section.id) {
    sectionContent = (
      <div className={classes.processingSectionContent}>
        <div className={classes.progressBarOuter}>
          <div
            className={classes.progressBarInner}
            style={{
              width: `${processingProgress.percentage}%`,
            }}
          />
        </div>
      </div>
    );
  }

  const isAllowedToDelete =
    ((section.userId === user?.id || meeting?.userId === user?.id) &&
      meeting?.userRole !== 'contributor-closed') ||
    section.path === 'placeholder' ||
    user?.orgRole?.access === ORG_ROLES.OWNER;

  const isAllowedToDrag =
    section.userId &&
    (section.userId === user?.id || meeting?.userRole === 'creator') &&
    section.path !== 'placeholder' &&
    meeting?.userRole !== 'contributor-closed';

  const isAllowedToSave =
    section.path !== 'placeholder' &&
    !section.SavedSection &&
    !hasFavoriteButtonBeenClicked;

  const isStarButtonVisible =
    (isHovering && isAllowedToSave) ||
    !!section.SavedSection ||
    hasFavoriteButtonBeenClicked;

  const isContributed = section.userId !== user?.id && classes.contributed;

  return (
    <li
      ref={sectionRef}
      style={{
        display:
          meeting?.userRole !== 'creator' && section.type === 'assigned-section'
            ? 'none'
            : '',
      }}
      className={classNames(classes.section, {
        [classes.placeholder]: section.path === 'placeholder',
        [classes.contributed]:
          isContributed || section.type === 'assigned-section',
        [classes.active]: isActive,
      })}
      onMouseOver={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={() => {
        if (section.path === 'placeholder') {
          return;
        }

        setCurrentSectionId(section?.id);
        setHasClickedOnSection(true);

        if (section.type !== 'question') {
          setIsQuestionFormVisible(false);
        }

        stopVideoPlayer();
        setVideoPlayerSource(section);
      }}
    >
      {section.type === 'video' && (
        <img src={playIcon} alt="Play" className={classes.playIcon} />
      )}
      {isAllowedToDelete && (
        <DeleteSectionIcon
          onClick={(event) => {
            event.stopPropagation();

            if (section.path === 'placeholder') {
              stopVideoPlayer();
              hidePlaceholder();
              setIsSectionCreationStopped(true);
              setTimeout(() => setIsSectionCreationStopped(false), 500);
              return;
            }

            hideQuestionAndAssignSectionForms();
            setCurrentSectionId(section?.id);

            if (user?.id === section.userId) {
              setIsConfirmSectionRemovalVisible(true);
            } else {
              setIsNotAllowedToDeleteSectionMessageVisible(true);
            }
          }}
        />
      )}
      {isStarButtonVisible && (
        <i
          className={classes.saveIcon}
          onClick={async (event) => {
            try {
              if (!isAllowedToSave) {
                return;
              }

              event.stopPropagation();

              // setIsSectionSavedModalVisible(false);
              // setTimeout(() => {
              //   setIsSectionSavedModalVisible(false);
              // }, 2500);

              setIsFavoritedPopupVisible(true);
              setHasFavoriteButtonBeenClicked(true);

              await SectionService.saveSection(section.id);
            } catch (error) {
              console.log(error);
            }
          }}
        >
          <img src={saveIcon} alt="Save" />
        </i>
      )}
      <div
        className={classes.sectionContentContainer}
        draggable={isAllowedToDrag}
        onDragStart={(event) =>
          event.dataTransfer.setData('sectionIndex', index)
        }
      >
        {sectionContent}
      </div>
      {section.type === 'assigned-section' && (
        <UserIcon
          classes={classes}
          creatorPhotoPath={section.assigned?.photoPath}
          creatorName={section.assigned?.name || section.assignedEmail}
        />
      )}
      {isContributed && section.path !== 'placeholder' && (
        <UserIcon
          classes={classes}
          creatorPhotoPath={section.creator?.photoPath}
          creatorName={section.creator?.name}
        />
      )}
      <div className={classes.title}>
        {section.path === 'placeholder' ? (
          <input
            ref={titleInputRef}
            type="text"
            value={sectionTitle}
            onChange={(event) => setSectionTitle(event.target.value)}
          />
        ) : (
          <input
            ref={titleInputRef}
            type="text"
            onClick={(event) => event.stopPropagation()}
            value={newSectionTitle}
            onChange={(event) => {
              setShouldUpdateTitle(true);
              setNewSectionTitle(event.target.value);
            }}
          />
        )}
      </div>
      <FavoritedPopup
        sectionRef={sectionRef}
        isVisible={isFavoritedPopupVisible}
        setIsVisible={setIsFavoritedPopupVisible}
      />
    </li>
  );
}

export default memo(Section);
