import { useState, useContext, useRef, useCallback } from 'react';

import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { arrayMoveImmutable } from 'array-move';

import UserService from '../../services/UserService';
import SectionService from '../../services/SectionService';
import useCheckMobileScreen from '../../hooks/useCheckMobileScreen';
import MeetingService from '../../services/MeetingService';
import { UiContext } from '../../context/UiContext';
import { MEETING_STATUSES } from '../../constants/main';
import UndoStack from './UndoStack';

function useMeetingQueries({
  meetingId: id,
  prevSectionId,
  setPrevSectionId,
  // setIsFileUploadProgressModalVisible,
  //  setUploadProgress,
}) {
  const [file, setFile] = useState(null);
  const [audioImage, setAudioImage] = useState(null);
  const [question, setQuestion] = useState(null);
  const [isQuestionFormVisible, setIsQuestionFormVisible] = useState(false);
  const [firstPlaceholderTitle, setFirstPlaceholderTitle] = useState(true);
  const [isPublishMeetingPopupVisible, setIsPublishMeetingPopupVisible] =
    useState(false);
  const [meetingTitle, setMeetingTitle] = useState('');
  const [meetingId, setMeetingId] = useState(null);
  const [currentSectionId, setCurrentSectionId] = useState(null);
  const [videoPlayerSource, setVideoPlayerSource] = useState('stream');
  const [isConfirmSectionRemovalVisible, setIsConfirmSectionRemovalVisible] =
    useState(false);
  const [sections, setSections] = useState(null);
  const [
    isNotAllowedToDeleteSectionMessageVisible,
    setIsNotAllowedToDeleteSectionMessageVisible,
  ] = useState(false);

  const undoStack = useRef(new UndoStack());

  const { notifyError } = useContext(UiContext);

  const navigate = useNavigate();

  const isMobile = useCheckMobileScreen();

  const queryClient = useQueryClient();

  const { data: user } = useQuery('me', UserService.getMe);

  const { data: fetchedMeeting, refetch: refetchMeeting } = useQuery(
    ['meeting', meetingId],
    () => {
      return MeetingService.getMeeting(meetingId);
    },
    {
      enabled: !!meetingId,
      keepPreviousData: true,
    }
  );

  const { data: fetchedSections, refetch } = useQuery(
    ['sections', id],
    () => SectionService.getSections(id),
    {
      enabled: !!id,
      keepPreviousData: true,
    }
  );

  const { mutate: updateMeeting } = useMutation(MeetingService.updateMeeting, {
    onSuccess: (meeting) => {
      if (meeting.status !== 'in progress') {
        setMeetingId(meeting.id);

        refetchMeeting();
      } else if (!isMobile) {
        setTimeout(() => {
          navigate(
            meeting.folderId ? `/my-library/${meeting.folderId}` : '/my-videos'
          );
        }, 5000);
      } else {
        queryClient.invalidateQueries('meetings');
      }

      setIsPublishMeetingPopupVisible(true);
    },
    onError: (error) => {
      console.log(error);

      notifyError(error?.response?.data?.message);
    },
  });

  const { mutate: updateMeetingMutation } = useMutation(
    MeetingService.updateMeeting,
    {
      onSuccess: () => {
        refetchMeeting();
      },
    }
  );

  const { mutate: updateMeetingTitleMutation } = useMutation(
    MeetingService.updateMeeting,
    {
      onSuccess: () => {
        const oldMeeting = fetchedMeeting;

        if (oldMeeting) {
          undoStack.current.push(() => {
            console.log('undo update meeting title');
            updateMeetingMutation({
              meetingId: oldMeeting.id,
              title: oldMeeting.title,
            });
          });
        }

        refetchMeeting();
      },
    }
  );

  const updateMeetingTitle = (title) => {
    if (!fetchedMeeting || isMobile) {
      setMeetingTitle(title);

      return;
    }

    setMeetingTitle(title);
    updateMeetingTitleMutation({
      meetingId: fetchedMeeting.id,
      title,
    });
  };

  const { mutate: undoSectionDelete } = useMutation(
    (sectionId) => {
      return SectionService.toggleSectionDeleteStatus(sectionId);
    },
    {
      onSuccess: () => {
        refetch();
        refetchMeeting();
      },
    }
  );

  const { mutate: deleteSectionWithAbilityToUndo } = useMutation(
    (sectionId) => {
      return SectionService.toggleSectionDeleteStatus(sectionId);
    },
    {
      onSuccess: (section) => {
        undoStack.current.push(() => {
          console.log('undo delete section id:', section.id);
          undoSectionDelete(section.id);
        });

        if (section.id === videoPlayerSource.id) {
          const newSource = sections.filter(
            (sctn) => sctn.id !== section.id
          )?.[0];
          setVideoPlayerSource(newSource || null);
        }

        refetch();
        refetchMeeting();
        setCurrentSectionId(null);
        setIsConfirmSectionRemovalVisible(false);
      },
    }
  );

  const { mutate: deleteSectionMutation } = useMutation(
    SectionService.deleteSection,
    {
      onSuccess: (section) => {
        if (section.id === videoPlayerSource.id) {
          const newSource = sections.filter(
            (sctn) => sctn.id !== section.id
          )?.[0];
          setVideoPlayerSource(newSource || null);
        }

        refetch();
        refetchMeeting();
        setCurrentSectionId(null);
        setIsConfirmSectionRemovalVisible(false);
      },
    }
  );

  const deleteSection = useCallback(() => {
    const sectionId = currentSectionId || sections[sections.length - 1]?.id;
    deleteSectionWithAbilityToUndo(sectionId);
  }, [currentSectionId, sections, deleteSectionWithAbilityToUndo]);

  const { mutate: createSection, isLoading: isSectionBeingCreated } =
    useMutation(
      (values) => {
        // const updateProgress = null;
        if (values.images || values.file) {
          // setIsFileUploadProgressModalVisible(true);
          // updateProgress = setUploadProgress;
        }

        if (values.images) {
          return SectionService.createImageSection({
            ...values,
            prevSectionId,
            //  setUploadProgress: updateProgress,
          });
        }

        return SectionService.createSection({
          ...values,
          prevSectionId,
          //  setUploadProgress: updateProgress,
        });
      },
      {
        onSuccess: (section) => {
          undoStack.current.push(() => {
            deleteSectionMutation({ sectionId: section.id });
          });

          if (section.type === 'video' || section.type === 'audio') {
            setFile(null);
            setAudioImage(null);
          } else if (section.type === 'question') {
            setIsQuestionFormVisible(false);
            setQuestion(null);
          }

          // setSaveSectionSuccessMessage('Section Successfully Saved!');
          // setIsSaveSectionPopupVisible(true);
          refetch();
          setFirstPlaceholderTitle(false);
          if (fetchedSections?.length) {
            refetchMeeting();
          }

          if (isMobile) {
            /*  showConfirmActionModal({
          title: 'Upload Complete',
          message: 'Your video has been saved in My Videos > My Mobile Uploads',
          noButtons: true,
        }); */
            updateMeeting({
              meetingId: section.meetingId,
              title: meetingTitle,
              status: MEETING_STATUSES.PUBLISHED,
            });
          }
          setPrevSectionId(null);
        },
        onError: (error) => {
          console.log(error);
          setPrevSectionId(null);
          setFile(null);
          setAudioImage(null);
          setIsQuestionFormVisible(false);
          setQuestion(null);
        },
      }
    );

  const { mutate: updateSection } = useMutation(SectionService.updateSection, {
    onSuccess: () => {
      refetchMeeting();
      refetch();
    },
  });

  const { mutate: deleteImagesFromSection } = useMutation(
    async ({ sectionId, imageIds }) => {
      // eslint-disable-next-line no-shadow
      const promises = imageIds.map((id) => {
        return SectionService.deleteSectionImage({ sectionId, imageId: id });
      });

      return Promise.all(promises);
    },
    {
      onSuccess: () => {
        refetch();
        refetchMeeting();
      },
    }
  );

  const { mutate: addImagesToSection } = useMutation(
    async ({ sectionId, files }) => {
      // eslint-disable-next-line no-shadow
      const promises = files.map((file) => {
        return SectionService.addSectionImage({ sectionId, image: file });
      });

      return Promise.all(promises);
    },
    {
      onSuccess: () => {
        refetch();
        refetchMeeting();
      },
    }
  );

  const { mutate: updateTextSection } = useMutation(
    SectionService.updateSection,
    {
      onSuccess: (section) => {
        const oldSection = fetchedSections.find(
          (sctn) => sctn.id === section.id
        );

        if (oldSection) {
          undoStack.current.push(() => {
            console.log('undo update text section id:', section.id);
            updateSection({
              sectionId: oldSection.id,
              note: {
                text: oldSection.text,
              },
            });
          });
        }

        refetchMeeting();
        refetch();
      },
    }
  );

  const { mutate: updateQuestionSection } = useMutation(
    SectionService.updateSection,
    {
      onSuccess: (section) => {
        const oldSection = fetchedSections.find(
          (sctn) => sctn.id === section.id
        );

        if (oldSection) {
          undoStack.current.push(() => {
            console.log('undo update question section id:', section.id);
            updateSection({
              sectionId: oldSection.id,
              question: {
                isAnswerRequired: oldSection.Question.isAnswerRequired,
                text: oldSection.Question.text,
                options: oldSection.Question.options,
              },
            });
          });
        }

        refetchMeeting();
        refetch();
      },
    }
  );

  const { mutate: renameSection } = useMutation(SectionService.updateSection, {
    onSuccess: (section) => {
      const fetchedSection = fetchedSections.find(
        (sctn) => sctn.id === section.id
      );

      if (fetchedSection) {
        undoStack.current.push(() => {
          console.log('undo rename section id:', section.id);
          updateSection({ sectionId: section.id, title: fetchedSection.title });
        });
      }

      // Refetch disabled because it caused sections to rerender and blink
      // refetchMeeting();
      // refetch();
    },
  });

  const updateSectionTitle = useCallback(
    (section) => {
      if (!section) {
        return;
      }

      renameSection({
        sectionId: section.id,
        title: section.title,
      });
    },
    [renameSection]
  );

  const changeSectionPosition = useCallback(
    (sectionIndex, newIndex) => {
      const newSectionsArray = arrayMoveImmutable(
        sections,
        sectionIndex,
        newIndex
      );
      const oldSectionsArray = [...sections];

      setSections(newSectionsArray);

      newSectionsArray.forEach((section, index) => {
        if (
          section.userId !== user?.id &&
          fetchedMeeting?.userRole !== 'creator'
        ) {
          return;
        }
        updateSection({ sectionId: section.id, order: index });
      });

      undoStack.current.push(() => {
        console.log('undo change section position');
        setSections(oldSectionsArray);
        oldSectionsArray.forEach((section, index) => {
          if (
            section.userId !== user?.id &&
            fetchedMeeting?.userRole !== 'creator'
          ) {
            return;
          }
          updateSection({ sectionId: section.id, order: index });
        });
      });
    },
    [fetchedMeeting?.userRole, sections, updateSection, user?.id]
  );

  return {
    fetchedSections,
    refetch,
    fetchedMeeting,
    refetchMeeting,
    createSection,
    setMeetingTitle,
    meetingTitle,
    updateMeeting,
    isPublishMeetingPopupVisible,
    isQuestionFormVisible,
    setIsQuestionFormVisible,
    question,
    setQuestion,
    file,
    setFile,
    audioImage,
    setAudioImage,
    firstPlaceholderTitle,
    setFirstPlaceholderTitle,
    meetingId,
    setMeetingId,
    deleteSection,
    undo: undoStack.current?.undo,
    canUndo: undoStack.current?.stack?.length,
    setSections,
    setCurrentSectionId,
    currentSectionId,
    setVideoPlayerSource,
    sections,
    videoPlayerSource,
    isConfirmSectionRemovalVisible,
    setIsConfirmSectionRemovalVisible,
    updateSection,
    changeSectionPosition,
    updateSectionTitle,
    updateTextSection,
    updateMeetingTitle,
    updateQuestionSection,
    isNotAllowedToDeleteSectionMessageVisible,
    setIsNotAllowedToDeleteSectionMessageVisible,
    isSectionBeingCreated,
    deleteImagesFromSection,
    addImagesToSection,
  };
}

export default useMeetingQueries;
